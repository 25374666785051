import { Stack, Typography } from "@mui/material";
import {
  CardContentContainerStyled,
  CardContentItemStyled,
  CardStyled,
} from "../styled";
import { useMobile } from "../../themes";

interface UsageProps {
  count: number;
  title: string;
  decimalPlaces?: number;
}
const Usage = ({ count, title, decimalPlaces = 2 }: UsageProps) => {
  return (
    <Stack direction={"column"}>
      <Typography fontWeight={600}>
        {Number(count.toFixed(decimalPlaces) ?? 0)}
      </Typography>
      <Typography variant="subtitle2">{title}</Typography>
    </Stack>
  );
};
interface Props {
  hoursUsed: number;
  hoursAvailable: number;
  hoursAverage: number;
  suggestedAvg: number;
}
export const TermUsageCard = ({
  hoursUsed,
  hoursAvailable,
  hoursAverage,
  suggestedAvg,
}: Props) => {
  const isMobile = useMobile();

  return (
    <CardStyled
      sx={{
        my: 1,
        minHeight: "75px",
        overflow: "auto",
      }}
    >
      <CardContentContainerStyled container minHeight={"100%"}>
        <CardContentItemStyled
          item
          display={"flex"}
          justifyContent={isMobile ? "flex-start" : "space-between"}
          px={2}
          py={isMobile ? 1 : 2}
          alignItems={"flex-start"}
          flexDirection={isMobile ? "column" : "row"}
          gap={1}
          height={"100%"}
          minHeight={"100%"}
        >
          <Usage count={hoursUsed} title="Already used" decimalPlaces={1} />
          <Usage count={hoursAvailable} title="Available" decimalPlaces={1} />
          <Usage count={hoursAverage} title={"Average monthly usage"} />
          <Usage
            count={Number(Number(suggestedAvg).toFixed(2))}
            title={"Suggested average"}
          />
        </CardContentItemStyled>
      </CardContentContainerStyled>
    </CardStyled>
  );
};
