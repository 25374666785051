import {
  Box,
  Button,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { actions, authSelectors } from "../../../../state";
import { SendIcon } from "../../../icons";
import {
  GetTaskChatsApiResponse,
  GetTaskWatchersApiResponse,
} from "../../../../state/rtk-query/state/tasks";
import { ArrayElement } from "../../../../global";
import { CustomCheckBox } from "../../../styled";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useAdmireUsersList,
  useCustomEditor,
  useOrgId,
  useOrgUsersList,
} from "../../../hooks";
import { CustomEditorBody, CustomEditorHeader } from "../../../inputs";
import { InlineAttachmentsList } from "../../../misc";
import { useMobile } from "../../../../themes";
const {
  useCreateTaskChatMutation,
  useUpdateTaskChatMutation,
  useSaveMediaMutation,
  useCreateTaskMediaMutation,
  useGetTaskWatchersQuery,
  useToggleTaskActionPendingMutation,
} = actions;

type TaskWatcherUser = GetTaskWatchersApiResponse["rows"][number];
interface Prop {
  taskId: number;
  orgId: number;
  chat?: ArrayElement<GetTaskChatsApiResponse["rows"]> | undefined;
  closeUpdate?: () => void;
  isInternalTask?: boolean;
  currentActionPending?: "admire" | "client" | null;
  isComplete?: boolean;
  assigned_to_user?: TaskWatcherUser | undefined;
  client_assignee_user?: Partial<TaskWatcherUser> | undefined | null;
  showFullEditor?: boolean;
}
export const ChatEditor = ({
  orgId: _orgId,
  taskId,
  chat,
  closeUpdate,
  isInternalTask = false,
  currentActionPending,
  isComplete,
  showFullEditor = false,
}: Prop) => {
  const { palette } = useTheme();
  const isMobile = useMobile();
  const isInternalUser = useSelector(authSelectors.isInternal);
  const curOrg = useOrgId();
  const orgId = isInternalUser ? _orgId : curOrg;
  const [isInternal, setIsInternal] = useState(isInternalTask);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [editorSectionFocus, setEditorSectionFocus] = useState(false);

  const initialAssignToOther =
    ((isInternalUser && currentActionPending !== "client") ||
      (!isInternalUser && currentActionPending !== "admire")) &&
    !isInternalTask &&
    !chat?.id &&
    !isComplete;
  const [assignToOther, setAssignToOther] = useState(true);

  const [saveMedia] = useSaveMediaMutation();
  const [createChat] = useCreateTaskChatMutation();
  const [updateChat] = useUpdateTaskChatMutation();
  const [createTaskMedia] = useCreateTaskMediaMutation();
  const [toggleActionPending] = useToggleTaskActionPendingMutation();
  const [submitting, setSubmitting] = useState(false);

  const { currentData: taskWatchers } = useGetTaskWatchersQuery(
    taskId && curOrg ? { id: taskId, orgId: curOrg } : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const { admireUsers } = useAdmireUsersList();
  const { orgUsers } = useOrgUsersList(orgId, !orgId || isInternal);
  const mentionables = useMemo(() => {
    const twIds = new Set((taskWatchers?.rows || []).map((w) => w.user_id));
    return [
      ...admireUsers.filter((u) => isInternalUser || twIds.has(u.id)),
      ...orgUsers,
    ].map(({ first_name, last_name }) =>
      [first_name, last_name].filter(Boolean).join("_"),
    );
  }, [taskWatchers, orgUsers, admireUsers, isInternalUser]);

  const markAsInternal = useCallback(
    (_e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setIsInternal(() => checked || isInternalTask);
    },
    [isInternal, taskId],
  );

  const placeholder = isComplete ? "Comments are disabled" : "Comment";
  const editor = useCustomEditor({
    placeholder,
    suggestions: mentionables,
    defaultText: chat?.message || "",
    dependencies: [mentionables],
    setAttachments,
  });

  const sendMsg = async () => {
    setSubmitting(true);
    const msg = (editor && !editor?.isEmpty && editor.getHTML()) || "";
    if ((msg && msg.trim() !== "") || attachments.length) {
      if (chat && chat?.id) {
        await updateChat({
          id: chat.id,
          orgId,
          body: {
            message: msg.trim(),
            is_internal: chat?.is_internal || isInternal || false, //maybe default to true or update should be able to change value
          },
        });
        if (closeUpdate) {
          closeUpdate();
        }
      } else {
        if (orgId) {
          const createdChat = await createChat({
            id: taskId,
            orgId,
            body: {
              message: msg.trim(),
              is_internal: isInternalTask || isInternal || false,
              has_media: attachments.length > 0,
            },
          }).unwrap();

          if (attachments.length > 0) {
            [...attachments].map(async (file: any) => {
              const { name } = file ?? {};
              const formData = new FormData();
              formData.append("file", file);

              const missingLabel = name.split(".")[0];
              const retFile = await saveMedia({
                body: { file: formData }, //! is this supposed to be a blob?
                label: missingLabel,
                name: name || missingLabel,
              }).unwrap();

              if (taskId && retFile.id && createdChat) {
                await createTaskMedia({
                  id: taskId,
                  orgId,
                  body: {
                    media_id: retFile.id,
                    chat_id: createdChat?.id,
                    is_internal: false,
                  },
                });
              }
              return;
            }),
              setAttachments([]);
          }
          if (!isInternal && initialAssignToOther && assignToOther) {
            toggleActionPending({
              id: taskId,
              orgId,
              body: { action_pending: isInternalUser ? "client" : "admire" },
            });
          }
          setEditorSectionFocus(false);
        }
      }
      editor?.commands.setContent("");
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (editor) {
      if (isComplete) {
        editor.setOptions({ editable: false });
      } else {
        editor.setOptions({ editable: true });
      }
    }
  }, [editor, isComplete]);

  useEffect(() => {
    if (editor?.isFocused) {
      setEditorSectionFocus(true);
    }
  }, [editor?.isFocused]);
  const onClickAway = useCallback(() => {
    if (!editor?.isFocused) {
      setEditorSectionFocus(false);
    }
  }, [editor?.isFocused]);

  const showEditor = editorSectionFocus || showFullEditor;

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Stack
        direction={"column"}
        mt={1}
        mb={2}
        sx={{ border: `1px solid ${palette.grey["500"]}`, borderRadius: "4px" }}
      >
        {showEditor && (
          <CustomEditorHeader
            editor={editor}
            showUpload={{ taskId, setAttachments }}
          />
        )}
        <CustomEditorBody
          editor={editor}
          placeholder={placeholder}
          isComplete={isComplete}
          editorSectionFocus={showEditor}
        />
        <InlineAttachmentsList
          attachments={attachments}
          setAttachments={setAttachments}
        />
        {showEditor && (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              backgroundColor: palette.grey[200],
              px: 2,
            }}
          >
            <Stack
              direction={isMobile ? "column" : "row"}
              alignItems={isMobile ? "flex-start" : "center"}
            >
              {isInternalUser && !isInternalTask && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <CustomCheckBox
                        onChange={markAsInternal}
                        checked={isInternal}
                        props={{ disabled: isComplete }}
                      />
                    }
                    disabled={isComplete}
                    label="Internal"
                  />
                </FormGroup>
              )}
              {initialAssignToOther && (
                <FormControlLabel
                  control={
                    <CustomCheckBox
                      onChange={(_, c) => setAssignToOther(c)}
                      checked={assignToOther && !isInternal}
                    />
                  }
                  disabled={isInternal}
                  label={`Assign task to ${
                    isInternalUser ? "client" : "Admire"
                  }`}
                />
              )}
            </Stack>
            <Box>
              {chat && <Button onClick={closeUpdate}>Cancel</Button>}
              <IconButton
                color="primary"
                onClick={sendMsg}
                disabled={
                  submitting || (editor?.isEmpty && !attachments.length)
                }
              >
                <SendIcon fontSize="small" />
              </IconButton>
            </Box>
          </Stack>
        )}
      </Stack>
    </ClickAwayListener>
  );
};
