import { Link, Stack, useTheme } from "@mui/material";
import {
  useGetOrgByIdInternalQuery,
  useGetOrgByIdQuery,
} from "../../../state/rtk-query/state/organization";
import { BuildingIcon, LockIcon } from "../../icons";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "react-redux";
import { authSelectors } from "../../../state";
import { castZero, RouterLink } from "../../../lib";
import { StartCwTimerButton } from "../../buttons";
import { GetTaskApiResponse } from "../../../state/rtk-query/state/tasks";

interface Props {
  orgId?: number;
  taskId?: number;
  taskTimeSpent?: number | null;
  type?: GetTaskApiResponse["type"];
  taskCode?: string;
  taskTitle?: string;
}
export const TimerBanner = ({
  orgId,
  taskId,
  type,
  taskCode,
  taskTitle,
  taskTimeSpent,
}: Props) => {
  const { palette } = useTheme();
  const isInternal = useSelector(authSelectors.isInternal);
  const { currentData: orgInfo } = isInternal
    ? useGetOrgByIdInternalQuery(orgId ? { orgId } : skipToken, {
        refetchOnMountOrArgChange: true,
      })
    : useGetOrgByIdQuery(orgId ? { orgId } : skipToken, {
        refetchOnMountOrArgChange: true,
      });
  const isInternalTask = type === "internal_task";

  if (!taskId) return null;

  return (
    <Stack
      p={"20px 32px"}
      sx={{
        bgcolor: isInternalTask ? palette.warning[100] : palette.primary[100],
      }}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Stack direction={"row"} alignItems={"center"}>
        {isInternalTask ? <LockIcon size={18} /> : <BuildingIcon size={18} />}
        <Link
          to={`/organizations/${orgInfo?.id}`}
          component={RouterLink}
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            ml: 2,
          }}
          color={palette.grey[900]}
        >
          {orgInfo?.name}
        </Link>
      </Stack>

      <StartCwTimerButton
        type={type}
        taskTimeSpent={taskTimeSpent}
        orgId={castZero(orgId)}
        taskId={castZero(taskId)}
        taskCode={taskCode ?? ""}
        taskTitle={taskTitle ?? ""}
      />
    </Stack>
  );
};
