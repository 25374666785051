import {
  Field,
  FieldArray,
  FieldArrayConfig,
  FormikValues,
  useFormikContext,
} from "formik";
import { TextField, TextFieldProps } from "formik-mui";
import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMobile } from "../../themes";
import {
  Button,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
  TextField as MUITextField,
  useTheme,
  SelectChangeEvent,
  FormHelperText,
} from "@mui/material";
import {
  ClockIcon,
  DatePicker,
  DateRange,
  DateRangePicker,
  DateTimePicker,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { AddIcon, CalendarBlankIcon, ChevronDown } from "../icons";
import { CountryCodes } from "../../lib/CountryCodes";
import useOs from "../hooks/useOs";
import { addOpacityToHex } from "../../lib";
import { Icon } from "@iconscout/react-unicons/dist/icon";

export type InputFieldProps = {
  label?: string;
  name: string;
  type?: string;
  disabled?: boolean;
  helperText?: string;
  displayHelperText?: boolean;
  dependencies?: any[];
  checkForAutoComplete?: boolean;
} & Partial<TextFieldProps>;

const getNestedField = (fieldName: string, obj: any) => {
  const keys = fieldName.split(/[.[\]]/).filter((key) => key !== "");
  return keys.reduce((acc: any, cur: any) => {
    return acc?.[cur];
  }, obj);
};

function TField(props: TextFieldProps) {
  const fieldRef = useRef();
  return <TextField {...props} inputRef={fieldRef} />;
}

export const InputField = ({
  label,
  name,
  type = "text",
  disabled = false,
  helperText = "",
  checkForAutoComplete = false,
  ...textFieldProps
}: InputFieldProps) => {
  const { touched, errors, values, setFieldTouched } =
    useFormikContext<FormikValues>();
  const isMobile = useMobile();
  useEffect(() => {
    const inputs = window.document.querySelectorAll<HTMLInputElement>(
      ".MuiInputBase-input",
    );
    inputs.forEach((input: HTMLInputElement) => {
      let notAutoCompleted = true;
      if (input && checkForAutoComplete) {
        notAutoCompleted =
          window.getComputedStyle(input, ":-webkit-autofill")
            .backgroundColor === "rgba(0, 0, 0, 0)" ||
          window.getComputedStyle(input, ":-webkit-autofill")
            .backgroundColor === "rgb(0, 0, 0)" ||
          window.getComputedStyle(input, ":-webkit-autofill")
            .backgroundColor === "";
        if (!notAutoCompleted) {
          setFieldTouched(name, true, true);
          return;
        }
      }
    });
  }, [values[name]]);
  const showError = () => {
    return (
      getNestedField(name, touched) && Boolean(getNestedField(name, errors))
    );
  };

  return (
    <Field
      component={TField}
      fullWidth
      name={name}
      className={isMobile ? "mobile-root" : ".MuiTextField-root"}
      label={label}
      type={type}
      disabled={disabled}
      error={showError()}
      helperText={
        helperText === "" ? touched[name] && errors[name] : helperText
      }
      {...textFieldProps}
    />
  );
};

export function SmallDownIcon(props: any) {
  return <ChevronDown {...props} color="#636469" />;
}

function SelectDownArrow(props: Parameters<typeof Select>[0]) {
  return <Select {...props} IconComponent={SmallDownIcon} />;
}

type SelectFieldProps = {
  label?: string;
  name: string;
} & Parameters<typeof Select>[0];

export function SelectField({ label, name, ...props }: SelectFieldProps) {
  const { setFieldValue, getFieldMeta } = useFormikContext<FormikValues>();
  const { value, error, touched } = getFieldMeta(name);
  const handleChange = useCallback(
    (e: any) => setFieldValue(name, e.target.value),
    [],
  );
  const select = (
    <SelectDownArrow
      label={label}
      name={name}
      renderValue={
        props.multiple
          ? (selected) => {
              return (
                <Typography fontSize={12} fontWeight={500}>
                  {(selected as any[]).join(", ")}
                </Typography>
              );
            }
          : undefined
      }
      value={value}
      onChange={handleChange}
      error={!!touched && !!error}
      {...props}
    />
  );
  if (!!touched && !!error) {
    return (
      <>
        {select}
        <FormHelperText error>{error}</FormHelperText>
      </>
    );
  }
  return select;
}

export function IdNameSelectField(
  props: SelectFieldProps & { menuitems: { id: number; name: string }[] },
) {
  const items = useMemo(() => {
    return props.menuitems.map(({ id, name }) => {
      return (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      );
    });
  }, [props.menuitems]);
  return <SelectField {...props}>{items}</SelectField>;
}

export function DatePickerField({
  name,
  error,
  ...props
}: Parameters<typeof DateRangePicker>[0] & { name: string; error?: boolean }) {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const handleDateChange = (newValue: DateRange<unknown>) => {
    setFieldValue(name, newValue);
  };

  return (
    <DateRangePicker
      slots={{
        field: SingleInputDateRangeField,
      }}
      slotProps={{
        textField: {
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <CalendarBlankIcon />
              </InputAdornment>
            ),
          },
          error,
        },
      }}
      value={values[name]}
      onChange={handleDateChange}
      {...props}
    />
  );
}

export function SingleDatePickerField({
  name,
  error,
  onChange,
  Icon,
  ...props
}: Parameters<typeof DatePicker>[0] & {
  name: string;
  error?: boolean;
  Icon?: Icon;
}) {
  const isMobile = useMobile();
  const [open, setOpen] = useState(false);

  const { values, setFieldValue, errors, touched } =
    useFormikContext<FormikValues>();

  const handleDateChange = (newValue: unknown) => {
    setFieldValue(name, newValue);
  };
  const onClick = useCallback(() => setOpen((o) => !o), [open]);

  return (
    <DatePicker
      open={open}
      onClose={onClick}
      onChange={onChange ?? handleDateChange}
      value={!values[name] ? "" : new Date(values[name])}
      slotProps={{
        textField: {
          placeholder: "",
          onClick: onClick,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                {Icon ? (
                  <Icon width={14} height={14} />
                ) : (
                  <ClockIcon
                    width={14}
                    height={14}
                    style={{ fontSize: isMobile ? "1rem" : ".8rem" }}
                  />
                )}
              </InputAdornment>
            ),
          },
          error: error || Boolean(touched[name] && errors[name]),
          helperText: <>{touched && errors[name] ? errors[name] : ""}</>,
        },
        previousIconButton: {
          sx: { fontSize: "1em !important" },
        },
        nextIconButton: {
          sx: { fontSize: "1em !important" },
        },

        switchViewIcon: {
          sx: { fontSize: "1em !important" },
        },
      }}
      {...props}
    />
  );
}
export function SingleDateTimeField({
  name,
  ...props
}: Parameters<typeof DateTimePicker>[0] & { name: string }) {
  const isMobile = useMobile();
  const [open, setOpen] = useState(false);
  const { values, setFieldValue, errors, touched } =
    useFormikContext<FormikValues>();

  const handleDateChange = (newValue: unknown) => {
    setFieldValue(name, newValue);
  };
  const onClick = useCallback(() => setOpen((o) => !o), [open]);

  return (
    <DateTimePicker
      value={values[name]}
      open={open}
      onClose={onClick}
      slots={{
        textField: MUITextField,
      }}
      slotProps={{
        textField: {
          placeholder: "",
          onClick: onClick,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <ClockIcon style={{ fontSize: isMobile ? "1rem" : ".8rem" }} />
              </InputAdornment>
            ),
          },
          error: Boolean(touched[name] && errors[name]),
          helperText: touched && errors[name] ? <>{errors[name]}</> : "",
        },
        previousIconButton: {
          sx: { fontSize: "1em !important" },
        },
        nextIconButton: {
          sx: { fontSize: "1em !important" },
        },

        switchViewIcon: {
          sx: { fontSize: "1em !important" },
        },
      }}
      onChange={handleDateChange}
      {...props}
    />
  );
}

export const extractDigits = (input: string) => {
  const match = input.match(/\d+/g);
  if (!match) return "";
  const allDigits = match.join("");
  return allDigits;
};

export function PhoneWithCCField({ name }: { name: string }) {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const os = useOs();
  const phone = getNestedField(name, values);
  useEffect(() => {
    if (phone?.cc) {
      const findIndex = CountryCodes.findIndex(
        (cc) => cc.country_code === phone.cc,
      );
      if (findIndex > -1) {
        setFieldValue(`${name}.ccIndex`, findIndex);
        return;
      }
    }
    setFieldValue(`${name}.ccIndex`, 0);
    setFieldValue(`${name}.cc`, "1");
  }, [phone.ccIndex === undefined || phone.ccIndex === ""]);

  const ccMenuItems = useMemo(
    () =>
      CountryCodes.map((cc, i) => (
        <MenuItem key={i} value={i}>
          <Typography align="left" overflow="hidden" variant="labelSmall">
            {os === "Windows"
              ? cc.label + "  +" + cc.country_code
              : cc.flag + " " + cc.label}
          </Typography>
        </MenuItem>
      )),
    [os],
  );
  const renderValue = useCallback(
    (value: unknown) => {
      const typProps =
        os === "Windows"
          ? { variant: "body1" as "body1", marginTop: 0.3 }
          : { variant: "h1" as "h1", marginTop: 0.7 };
      return (
        <Typography {...typProps}>
          {os === "Windows"
            ? CountryCodes[value as number]?.country
            : CountryCodes[value as number]?.flag}
        </Typography>
      );
    },
    [os],
  );

  const onChange = useCallback((e: any) => {
    setFieldValue(`${name}.ccIndex`, e.target.value);
    setFieldValue(
      `${name}.cc`,
      CountryCodes[e.target.value].country_code.replace("+", ""),
    );
  }, []);

  return (
    <InputField
      name={`${name}.number`}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SelectDownArrow
              name={`${name}.ccIndex`}
              value={phone.ccIndex}
              onChange={onChange}
              renderValue={renderValue}
              sx={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              {ccMenuItems}
            </SelectDownArrow>
          </InputAdornment>
        ),
        sx: { p: 0 },
      }}
    />
  );
}

export interface ArrayFieldProps extends FieldArrayConfig {
  itemInitialValue: any;
  buttonName: string;
  minLength?: number;
}

export function ArrayField({
  buttonName,
  itemInitialValue,
  children,
  ...props
}: ArrayFieldProps) {
  return (
    <FieldArray {...props}>
      {(renderProps) => {
        return (
          <>
            {children ? children(renderProps) : undefined}
            <Button
              sx={{ pl: 0, height: 0 }}
              onClick={() => renderProps.push(itemInitialValue)}
            >
              <AddIcon />
              <Typography marginLeft={0.8}>{buttonName}</Typography>
            </Button>
          </>
        );
      }}
    </FieldArray>
  );
}
interface InlineProps {
  value: string;
  onBlur: () => void;
  onChange: (val: any) => void;
  startAdornment?: ReactNode;
  style?: CSSProperties;
  placeholder?: string;
}
export function InlineTextField({
  value,
  onChange,
  onBlur,
  startAdornment,
  placeholder,
  style,
}: InlineProps) {
  const { palette } = useTheme();
  return (
    <MUITextField
      sx={{
        width: "100%",
        "& .MuiInputBase-input:hover": {
          backgroundColor: addOpacityToHex(palette.grey[200], 0.8),
          borderRadius: "6px",
        },
        "& .MuiInputBase-input:focus": {
          backgroundColor: addOpacityToHex(palette.grey[200], 0.8),
          paddingLeft: "6px",
          borderRadius: "6px",
        },
      }}
      placeholder={placeholder}
      multiline
      onBlur={onBlur}
      onChange={onChange}
      variant={"standard"}
      value={value}
      InputProps={{
        disableUnderline: true, // !edit && !composeNew,
        startAdornment,
        inputProps: {
          style: {
            color: "#5D6995",
            width: "100%",
            whiteSpace: "break-spaces",
            ...style,
          },
        },
      }}
    />
  );
}
interface InlineSelectFieldProps {
  value: string | number;
  onChange: (val: SelectChangeEvent<string | number>) => void;
  options: { id: number; label: string }[];
  name: string;
}
export function InlineSelectField({
  value,
  onChange,
  options,
  name,
}: InlineSelectFieldProps) {
  const { palette } = useTheme();
  return (
    <Select
      labelId={`${name}-select`}
      name={name}
      onChange={onChange}
      value={value || ""}
      variant="standard"
      IconComponent={() => null}
      sx={{
        width: "fit-content",
        "& > .MuiInput-input": {
          fontSize: 14,
          color: palette.grey[900],
          fontWeight: 600,
        },
      }}
      disableUnderline
    >
      {options.map((o) => {
        return (
          <MenuItem
            sx={{
              "&:hover": {
                backgroundColor: palette.accent[50],
              },
              color: palette.grey[900],
              fontWeight: 500,
            }}
            key={o.id}
            value={o.id}
          >
            {o.label}
          </MenuItem>
        );
      })}
    </Select>
  );
}
