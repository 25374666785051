import { Box, Button, IconButton, Stack } from "@mui/material";
import { useGetReportByIdQuery } from "../../../state/rtk-query/state/reports";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Navigation, useLocation } from "../../../lib";
import {
  LeftArrowIcon,
  DownloadUnderline,
  ServerDataGrid,
  CardStyled,
} from "../../../components";
import { MainPages } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors, uiActions, uiSelectors } from "../../../state";
import { GridFilterModel, useGridApiRef } from "@mui/x-data-grid-pro";
import {
  useReportDynamicTable,
  useRtkQueryParams,
} from "../../../components/hooks";
import {
  buildFilterModel,
  convertToMuiDate,
} from "../../../lib/requestBuilder";

export const ReportPage = () => {
  const { pathname, params: urlParams } = useLocation();
  const [params, setParams] = useRtkQueryParams({
    id: urlParams.id,
  });

  const [renderDefault, setRenderDefault] = useState(false);
  const isInternal = useSelector(authSelectors.isInternal);
  const dynamicTitle = useSelector(uiSelectors.dynamicTitle);
  const pages = MainPages(isInternal);
  const id = Number(params?.id ?? 0);
  const { path: titlePath } = dynamicTitle ?? {};
  const dispatch = useDispatch();
  const ref = useGridApiRef();

  const paramsSearch = useMemo(() => {
    return {
      ...params,
      id,
    };
  }, [urlParams, id, params]);
  const {
    currentData: report,
    error,
    isFetching,
  } = useGetReportByIdQuery(paramsSearch ? paramsSearch : skipToken, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const { name, count, data, default_filter, actions } = report ?? {};
  const { reportData, columns } = useReportDynamicTable({
    id,
    paramsSearch,
    data,
    count,
    actions,
  });
  const defaultFilter = useMemo(() => {
    let items = default_filter?.items ?? [];

    if (items?.length) {
      items = items?.map((item, i) => {
        const type = item.type;

        if (type && ["months", "days"].includes(type)) {
          return {
            ...item,
            value: convertToMuiDate(item, type),
            id: i,
          };
        }
        if (type === "number") {
          return { ...item, value: Number(item.value), id: i };
        }
        return { ...item, id: i };
      }) as any;
    }
    return { items };
  }, [default_filter]);

  const [filterModel, setFilterModel] = useState<GridFilterModel>();

  useEffect(() => {
    if (!renderDefault && defaultFilter?.items?.length) {
      setFilterModel(defaultFilter as any);
      setRenderDefault(true);
    }
  }, [defaultFilter]);

  const returnToReportsPage = () => {
    Navigation.go(pages.reports.path);
  };

  useEffect(() => {
    // rtk query doesn't know the type of error https://github.com/reduxjs/redux-toolkit/issues/1337#issuecomment-885957883
    if (
      error &&
      "status" in error &&
      typeof error?.status === "number" &&
      [403, 401].includes(error.status)
    ) {
      returnToReportsPage();
    }
  }, [id, error]);
  useEffect(() => {
    if (titlePath !== pathname && name) {
      dispatch(
        uiActions.setDynamicTitle({
          path: pathname,
          value: (
            <Stack component={"span"} alignItems={"center"} direction={"row"}>
              <IconButton onClick={returnToReportsPage}>
                <LeftArrowIcon />
              </IconButton>

              {name}
            </Stack>
          ),
        }),
      );
    }
  }, [pathname, name]);

  const exportClick = () => {
    if (ref.current) {
      ref.current.exportDataAsCsv();
    }
  };
  const handleFilterChange = useCallback((newFilterModel: GridFilterModel) => {
    const filter = buildFilterModel(newFilterModel);
    if (!params?.filter && filter === "[]" && renderDefault) {
      return;
    } else if (params?.filter !== filter) {
      setParams({ filter });
      setFilterModel(newFilterModel);
    }
  }, []);
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        my={2}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{ width: "fit-content" }}
          onClick={exportClick}
        >
          <DownloadUnderline size={18} />
          <Box component={"span"} ml={1} fontWeight={500}>
            Report
          </Box>
        </Button>
      </Stack>
      <CardStyled sx={{ height: "100%", mb: 2 }}>
        <ServerDataGrid
          toolbarProps={{ showFilterOptions: true, hideExport: true }}
          isFetching={isFetching}
          rowCount={count ?? 0}
          rows={reportData ?? []}
          columns={columns}
          setQueryParams={setParams}
          currentParams={params}
          props={{
            density: "standard",
            sx: {
              ".MuiDataGrid-columnHeaderTitle": {
                textTransform: "uppercase",
              },
              ".MuiDataGrid-row": {
                cursor: "pointer",
              },
              // ".MuiDataGrid-cell": {
              //   maxWidth: "100% !important",
              // },
            },
            apiRef: ref,
            filterModel,
            onFilterModelChange: handleFilterChange,
          }}
        />
      </CardStyled>
    </>
  );
};
