import { actions } from "../../state/rtk-query/state";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";
import { TimerActions, TimerActivityTypes } from "../../lib";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { useGetTermItemTypesQuery } from "../../state/rtk-query/state/terms";
import { useGetTemplatesQuery } from "../../state/rtk-query/state/templates";

const {
  useGetAdmireUsersListQuery,
  useGetOrgUsersListQuery,
  useGetLicensesAndStatusesQuery,
  useGetTopicsQuery,
  useGetOrgsListQuery,
  useGetConsultantsQuery,
  useGetAccountManagersQuery,
  useGetStatusesQuery,
  useGetPositionsQuery,
  useGetTimerActivityTypesQuery,
  useGetTimerActionsQuery,
} = actions;

function copyData<D extends { id: number }[]>(data?: D) {
  if (!data) return [] as unknown as D;
  const data_ = [...data];
  return data_ as D;
}

function getName(user: { first_name: string; last_name: string }) {
  return user.first_name.toLowerCase() + user.last_name.toLowerCase();
}

function sortByName(
  a: { first_name: string; last_name: string },
  b: { first_name: string; last_name: string },
) {
  return getName(a).localeCompare(getName(b));
}

function getListAndGetter<D extends { id: number }[]>(
  data?: D,
  processList?: (list: D) => D,
) {
  const _copied = copyData(data);
  const copied = (processList && processList(_copied)) || _copied;
  const map = new Map(copied.map(({ id }, i) => [id, i]));
  const getData = (id?: number | null) => {
    if (id === undefined || id === null) return;
    const index = map.get(id);
    if (index === undefined) return;
    return copied[index] as D[number];
  };
  return { list: copied, getData };
}

export function useAdmireUsersList(skip?: boolean) {
  const { currentData: { data } = {} } = useGetAdmireUsersListQuery(
    skip ? skipToken : undefined,
  );

  return useMemo(() => {
    const { list, getData } = getListAndGetter(data, (d) => d.sort(sortByName));
    return { admireUsers: list, getAdmireUser: getData };
  }, [data]);
}

export function useOrgUsersList(orgId: number, skip?: boolean) {
  const { currentData: { data } = {} } = useGetOrgUsersListQuery(
    skip ? skipToken : { orgId },
  );

  return useMemo(() => {
    const { list, getData } = getListAndGetter(data, (d) => d.sort(sortByName));
    return { orgUsers: list, getOrgUser: getData };
  }, [data]);
}

export function useCombinedUsersList(orgId: number, skip?: boolean) {
  const { currentData: { data: admireUsers } = {} } =
    useGetAdmireUsersListQuery(skip ? skipToken : undefined);
  const { currentData: { data: orgUsers } = {} } = useGetOrgUsersListQuery(
    skip ? skipToken : { orgId },
  );
  return useMemo(() => {
    const { list, getData } = getListAndGetter(
      [
        ...(admireUsers || []).map((au) => ({ ...au, isInternal: true })),
        ...(orgUsers || []).map((ou) => ({ ...ou, isInternal: false })),
      ],
      (d) => d.sort(sortByName),
    );
    return { users: list, getUser: getData };
  }, [admireUsers, orgUsers]);
}

export function useConsultantsList(skip?: boolean) {
  const { currentData: { data } = {} } = useGetConsultantsQuery(
    skip ? skipToken : undefined,
  );
  return useMemo(() => {
    const { list, getData } = getListAndGetter(data, (d) => d.sort(sortByName));
    return { consultants: list, getConsultant: getData };
  }, [data]);
}

export function useAccountManagersList(skip?: boolean) {
  const { currentData: { data } = {} } = useGetAccountManagersQuery(
    skip ? skipToken : undefined,
  );
  return useMemo(() => {
    const { list, getData } = getListAndGetter(data, (d) => d.sort(sortByName));
    return { accountManagers: list, getAccountManager: getData };
  }, [data]);
}

export function useLicensesList(skip?: boolean) {
  const { currentData: { licenses } = {} } = useGetLicensesAndStatusesQuery(
    skip ? skipToken : undefined,
  );
  return useMemo(() => {
    const { list, getData } = getListAndGetter(licenses);
    const ls = getListAndGetter(
      (licenses || []).flatMap((l) => l.license_statuses),
    );
    return {
      licenses: list,
      getLicense: getData,
      licenseStatuses: ls.list,
      getLicenseStatus: ls.getData,
    };
  }, [licenses]);
}

export function useTopicsList(skip?: boolean) {
  const { currentData: { rows: topics } = {} } = useGetTopicsQuery(
    skip ? skipToken : undefined,
  );
  return useMemo(() => {
    const { list, getData } = getListAndGetter(topics);
    return { topics: list, getTopic: getData };
  }, [topics]);
}

export function useOrgsList(skip?: boolean) {
  const { data } = useGetOrgsListQuery(skip ? skipToken : undefined);
  return useMemo(() => {
    const { list, getData } = getListAndGetter(data, (d) =>
      d.sort((a, b) => a.name.localeCompare(b.name)),
    );
    return { orgs: list, getOrg: getData };
  }, [data]);
}

export function useTermItemTypesList(skip?: boolean) {
  const { currentData: { rows: termItemTypes } = {} } =
    useGetTermItemTypesQuery(skip ? skipToken : undefined);
  return useMemo(() => {
    const { list, getData } = getListAndGetter(termItemTypes);
    return { termItemTypes: list, getTermItemTypes: getData };
  }, [termItemTypes]);
}

export function useActivityTypesList(skip?: boolean) {
  const isInternal = useSelector(authSelectors.isInternal);

  const { currentData: { rows: types } = {} } = useGetTimerActivityTypesQuery(
    skip || !isInternal ? skipToken : undefined,
  );
  return useMemo(() => {
    const { list, getData } = getListAndGetter(types);
    return {
      activityTypes: list,
      getActivityTypes: getData,
      getActivityId: (activity?: TimerActivityTypes) => {
        if (!activity) return 0;
        return list?.find((a) => a?.internal_name === activity)?.id ?? 0;
      },
    };
  }, [types, isInternal]);
}

export function useActivityActionsList(skip?: boolean) {
  const isInternal = useSelector(authSelectors.isInternal);
  const { currentData: { rows: actions } = {} } = useGetTimerActionsQuery(
    skip || !isInternal ? skipToken : undefined,
  );
  return useMemo(() => {
    const { list, getData } = getListAndGetter(actions);
    return {
      actions: list,
      getActivityTypes: getData,
      getActionId: (action?: TimerActions) => {
        if (!action) return 0;
        return list?.find((a) => a?.internal_name === action)?.id ?? 0;
      },
    };
  }, [actions, isInternal]);
}

export function useStatusesList<
  StatusType extends
    | "all"
    | "all_tasks"
    | "session"
    | "task"
    | "internal_task"
    | "custom_work"
    | "quick_question" = "all",
>(type = "all" as StatusType, skip?: boolean) {
  const { data } = useGetStatusesQuery(skip ? skipToken : undefined);
  return useMemo(() => {
    const { list, getData } = getListAndGetter(
      data,
      type === "all"
        ? undefined
        : (d) =>
            d.filter((s) => {
              if (s.type === "all") return true;
              if (s.type === type) return true;
              if (s.type === "all_tasks" && type !== "session") return true;
              if (s.type !== "session" && type === "all_tasks") return true;
            }),
    );
    function getStatusByName<SType extends StatusType = StatusType>(
      internal_name: string,
    ): SType extends "all" | "all_tasks" ? number[] : number {
      const statusesByName = list
        .filter((s) => s.internal_name === internal_name)
        .map((s) => s.id);
      return (
        type === "all" || type === "all_tasks"
          ? statusesByName
          : statusesByName[0]
      ) as SType extends "all" | "all_tasks" ? number[] : number;
    }

    function getStatusByPhase(
      phase: (typeof list)[number]["transition_phase"],
    ) {
      return list.filter((s) => s.transition_phase === phase).map((s) => s.id);
    }

    function getEditableStatuses() {
      return list?.filter((ss) => !ss?.is_automated);
    }

    function getCompleteStatusByPhase() {
      const statuses = getStatusByPhase("done");
      const completeStatuses = new Set([21, 14, 20, 19, 13]);
      const status = statuses.find((s) => completeStatuses.has(s));
      if (!status) {
        throw new Error("Failed to get complete status for" + type);
      }
      return status;
    }

    return {
      statuses: list,
      getStatus: getData,
      getStatusByName,
      getStatusByPhase,
      getCompleteStatusByPhase,
      getEditableStatuses,
    };
  }, [data, type]);
}

export function usePositionsList() {
  const { currentData: { rows } = {} } = useGetPositionsQuery();
  return useMemo(() => {
    const { list, getData } = getListAndGetter(
      rows as (Omit<Exclude<typeof rows, undefined>[number], "id"> & {
        id: number;
      })[],
    );
    return { positions: list, getPosition: getData };
  }, [rows]);
}

export function useTemplatesList(skip?: boolean) {
  const { currentData } = useGetTemplatesQuery(undefined, {
    skip,
  });
  return useMemo(() => {
    const { list, getData } = getListAndGetter(currentData);
    return { templates: list, getTemplate: getData };
  }, [currentData]);
}
