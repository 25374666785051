import { Stack } from "@mui/material";
import { UpcomingSessionsSideBar, TasksTableInternal } from "../../components";
import { useMobile, useTablet } from "../../themes";
import { TaskStats } from "../misc";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";

export const OrgDashboardView = ({
  isNewAdmire,
}: {
  isNewAdmire?: boolean;
}) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isSmallerThanPc = isTablet || isMobile;
  const orgId = +useParams().id!;
  const isCW = useSelector(authSelectors.isCustomWorkUser);

  return (
    <Stack
      direction={isSmallerThanPc ? "column" : "row"}
      height={isMobile ? undefined : "100%"}
      width={"100%"}
      id="homepage-container"
    >
      <Stack
        height={"100%"}
        width={isSmallerThanPc || isCW ? "100%" : "75%"}
        maxWidth={isSmallerThanPc || isCW ? "100%" : "calc(100% - 450px)"}
        gap={2}
        pb={2}
      >
        <Stack
          direction={isSmallerThanPc ? "column" : "row"}
          justifyContent={"space-between"}
          width="100%"
          height={"auto"}
        >
          <TaskStats orgId={orgId} isNewAdmire={isNewAdmire} />
        </Stack>
        {!isSmallerThanPc && (
          <Stack overflow={"clip"} height="100%">
            <TasksTableInternal
              height="100%"
              orgId={orgId}
              isPageSubSection={true}
            />
          </Stack>
        )}
      </Stack>

      {!isCW && (
        <Stack
          width={isSmallerThanPc ? "100%" : "25%"}
          minWidth={isSmallerThanPc ? "100%" : "450px"}
        >
          <UpcomingSessionsSideBar
            orgId={orgId}
            sx={{
              p: 0,
              pr: 0,
              pl: isSmallerThanPc ? 0 : 4,
              width: "100%",
            }}
          />
        </Stack>
      )}

      {isSmallerThanPc && (
        <Stack height="100%">
          <TasksTableInternal
            height="100%"
            orgId={orgId}
            isPageSubSection={true}
          />
        </Stack>
      )}
    </Stack>
  );
};
