import { Typography, useTheme } from "@mui/material";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
//local
import { AppState, actions, useSelector } from "../../state";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useOrgId } from "../hooks";

const { useCreateSessionMutation, useGetOrgByIdQuery } = actions;
export function ScheduleSession({ sessionId }: { sessionId?: number }) {
  const { palette } = useTheme();
  const userInfo = useSelector((state: AppState) => state.auth.user);
  // TODO: need to update if consultants creating sessions
  const orgId = useOrgId();

  const [createSession] = useCreateSessionMutation();

  const { currentData: org } = useGetOrgByIdQuery(
    orgId ? { orgId } : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      if (org?.consultant?.id && org?.id) {
        await createSession({
          orgId,
          body: {
            assigned_to: org.consultant?.id,
            session_id: sessionId,
            ...e.data.payload,
          },
        });
      }
    },
  });

  // const [createWebhook] = useCreateWebhookMutation();
  return (
    <>
      {org?.personal_link ? (
        <>
          <InlineWidget
            url={org.personal_link ?? ""}
            styles={{
              backgroundColor: palette.primary.main,
              margin: 12,
              borderRadius: "3px",
              minHeight: "650px",
              height: "650px",
            }}
            prefill={{
              email: userInfo?.email,
              firstName: userInfo?.first_name,
              lastName: userInfo?.last_name,
              name: `${userInfo?.first_name} ${userInfo?.last_name}`,
              customAnswers: {
                a1: org?.name ?? "",
              },
            }}
          />
          {/*  FOR TESTING BUT IN THEORY WE SHOULD CREATE WEBHOOK IF DOESN'T EXIST
          <Button onClick={() => createWebhook()} sx={{ m: 2 }}>
            Create Webhook
          </Button>*/}
        </>
      ) : (
        <Typography fontSize={20} fontWeight={"bold"} color="primary.main">
          No consultant is associated to this org. Please reach out to get setup.
        </Typography>
      )}
    </>
  );
}
