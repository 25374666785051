import { Button, Link, Stack, Typography, useTheme } from "@mui/material";
import { formatDate } from "../../lib";
import {
  ClockIcon,
  AvatarGroup,
  CardStyled,
  CardContentContainerStyled,
  CardContentItemStyled,
  Modal,
  AddTaskButton,
  ScheduleSession,
  AttendeeAndAssigneeForm,
  JoinZoomMeetingButton,
} from "../../components";
import { memo, useCallback, useState, useMemo } from "react";
import { useMobile } from "../../themes";
import { GetSessionsByOrgIdApiResponse } from "../../state/rtk-query/state/session";
import { ArrayElement } from "../../global";
import { Link as RouterLink } from "react-router-dom";
import { useGetOrgByIdInternalQuery } from "../../state/rtk-query/state/organization";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { SessionTimeRange } from "../misc";
type SessionFromOrg = ArrayElement<GetSessionsByOrgIdApiResponse["rows"]>;
interface Props {
  session: SessionFromOrg;
  displayTodays?: boolean;
}
/* Maybe don't show the card at all if its missing these */
export const MiniSessionCard = memo(
  ({ session, displayTodays = true }: Props) => {
    const { palette } = useTheme();
    const isMobile = useMobile();
    const { booking, session_invitees, id, title, org_id } = session;
    const isInternal = useSelector(authSelectors.isInternal);
    const { currentData: org } = useGetOrgByIdInternalQuery(
      org_id ? { orgId: org_id } : skipToken,
      { skip: !isInternal, refetchOnMountOrArgChange: true },
    );

    const { start_time, end_time } = booking ?? {};

    const [openSessions, setOpenSessions] = useState(false);
    const [openAttendeeModal, setOpenAttendeeModal] = useState(false);
    const openSessionsModal = useCallback(() => {
      setOpenSessions(!openSessions);
    }, [openSessions]);

    const closeAttendeeModal = useCallback(
      () => setOpenAttendeeModal(false),
      [openAttendeeModal],
    );
    const clientAssignee =
      session?.client_assignee_user ?? session?.created_by_user;
    const users = useMemo(
      () => [
        { ...session.assigned_to_user, label: "Admire assignee" },
        { ...clientAssignee, label: "Client assignee" },
        ...(session_invitees || [])
          .map((u) =>
            u.user?.length ? u.user : { first_name: "", last_name: u.email },
          )
          .flat()
          .sort((a: any, b: any) => +!!b?.id - +!!a?.id),
      ],
      [session.assigned_to_user, clientAssignee, session_invitees],
    );

    const defaultTitle = displayTodays ? (
      org?.name || title
    ) : (
      <>
        {booking?.start_time ? (
          <>
            {" "}
            {formatDate(new Date(booking?.start_time ?? ""), "E, LLL d")}{" "}
            &middot; {title || "Consulting Session"}
          </>
        ) : null}
      </>
    );
    // I tested with more characters and it starts to wrap at 32 characters but I there should be a space
    // and the design is if its gonna wrap put next to time instead
    const titleTooLong = Number(title?.length ?? 0) >= 30;

    return (
      <>
        <CardStyled
          sx={{
            height: isMobile ? "100%" : 145,
            width: "100%",
            "&:hover": {
              border: `1px solid ${palette.primary[300]}`,
              boxShadow: `0 1px 4px rgba(85,95,230,0.5)`,
            },
          }}
        >
          <CardContentContainerStyled container height={"100%"}>
            <Link
              to={`/sessions/${session.id}`}
              component={RouterLink}
              sx={{
                fontSize: "16px",
                fontWeight: 600,
              }}
              color={palette.grey[900]}
              width={"100%"}
            >
              <CardContentItemStyled
                item
                display={"flex"}
                flexDirection={"column"}
                px={isMobile ? 1 : 2}
                py={1}
                sx={{ width: "100% !important" }}
                minWidth={"100%"}
                gap={1}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      width: "100%",
                    }}
                    color={palette.grey[900]}
                  >
                    {!titleTooLong
                      ? defaultTitle
                      : formatDate(
                          new Date(booking?.start_time ?? ""),
                          "LLL do",
                        )}
                  </Typography>

                  <AvatarGroup
                    users={users}
                    dimensions={24}
                    maxBeforeGrouping={2}
                    defaultLabel="Invitee"
                    labelForRemaining="Invitees"
                    setOpenModal={isInternal ? setOpenAttendeeModal : undefined}
                  />
                </Stack>

                <Stack direction={"row"} alignItems={"center"} mt={0.5}>
                  <Stack display={"flex"} alignSelf={"flex-start"}>
                    <ClockIcon fontSize="small" color={palette.primary.main} />
                  </Stack>
                  {start_time ? (
                    <Typography pl={1} fontSize={14} variant="subtitle2">
                      {`${
                        (titleTooLong || displayTodays) && title?.trim() !== ""
                          ? `${title}:`
                          : ""
                      } `}
                      <SessionTimeRange
                        startTime={start_time}
                        endTime={end_time}
                      />
                    </Typography>
                  ) : null}
                  {booking?.join_url && booking?.start_time ? (
                    <JoinZoomMeetingButton booking={booking} />
                  ) : !booking?.start_time ? (
                    <Button onClick={openSessionsModal}>Schedule</Button>
                  ) : null}
                </Stack>
                <AddTaskButton session={session} />
              </CardContentItemStyled>
            </Link>
          </CardContentContainerStyled>
        </CardStyled>

        <Modal open={openSessions} onClose={openSessionsModal}>
          <ScheduleSession sessionId={id} />
        </Modal>
        <Modal
          open={openAttendeeModal}
          onClose={closeAttendeeModal}
          sx={{ width: isMobile ? "90%" : "30%" }}
        >
          <AttendeeAndAssigneeForm
            onClose={closeAttendeeModal}
            session={session}
          />
        </Modal>
      </>
    );
  },
);
