import { Stack, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTaskColumns } from "../../columns";
import { actions, authSelectors, useSelector } from "../../state";
import { GetTasksApiResponse } from "../../state/rtk-query/state/tasks";
import { useOpenTaskDrawer, useRtkQueryParams } from "../hooks";
import { ServerDataGrid } from "./ServerDataGrid";
import { useStatusesList } from "../hooks/useLists";
import { TaskFilters } from "../misc/TaskFilters";
import { useLocation } from "../../lib";
import { ToggleDropdown } from "../buttons/ToggleDropdown";
import { useTaskParams } from "../hooks/useTaskParams";

const { useGetTasksQuery } = actions;
export const TasksTableInternal = ({
  height,
  orgId,
  isPageSubSection = false,
}: {
  height: string;
  orgId?: number;
  isPageSubSection?: boolean;
}) => {
  const { palette } = useTheme();
  const { query } = useLocation();
  const _inOrgView = orgId !== undefined;
  const userId = useSelector(authSelectors.userId);
  const isConsultant = useSelector(authSelectors.isConsultant);

  // taskcols is one render behind due to te extra useMemo's
  const [pageRendered, setPageRendered] = useState(false);
  useEffect(() => {
    if (!pageRendered) setPageRendered(true);
  }, []);
  const openTask = useOpenTaskDrawer();

  // NOTE: If there are ever more filters make sure the names don't overlap task query's
  const [filterQuery, setQuery] = useTaskParams(true);

  const {
    taskAssignees,
    taskStatuses,
    taskOrgs,
    taskPriority,
    taskInternalPriority,
    taskTopics,
    taskType,
    taskFilterMyOrgs,
    newComments,
    taskOpenTasks,
    taskAwaiting,
    taskArchive,
  } = filterQuery;

  const inOrgView = useMemo(() => _inOrgView, [orgId]);
  const tasksCols = useTaskColumns(
    inOrgView,
    isPageSubSection,
    pageRendered,
    taskArchive,
  );

  const statusType =
    !taskType || taskType.includes("unscheduled")
      ? "all_tasks"
      : taskType.includes("on_session")
      ? "task"
      : "all_tasks";

  const { statuses, getStatusByName } = useStatusesList(statusType);
  const openStatuses = statuses.filter((s) => s.transition_phase !== "done");

  const [taskParams, setTaskParams] = useRtkQueryParams();
  const isAccountManager = useSelector(authSelectors.isAccountManager);
  const params = useMemo(() => {
    const statuses_ = taskStatuses?.length
      ? taskStatuses.map((s) => getStatusByName(s)).flat()
      : taskOpenTasks !== false
      ? openStatuses.map((s) => s.id)
      : undefined;

    const defaultMyTasks =
      isConsultant && !taskAssignees ? [userId] : taskAssignees;

    return {
      ...taskParams,
      type: taskType,
      topics: taskTopics,
      statuses: statuses_,
      assignees: defaultMyTasks,
      priority: taskPriority,
      internalPriority: taskInternalPriority,
      orgs: inOrgView && orgId ? [orgId] : taskOrgs,
      filterByMyOrg:
        !inOrgView &&
        (isConsultant || isAccountManager) &&
        !taskOrgs?.length &&
        taskFilterMyOrgs !== false,
      filterNewComments: newComments,
      archived: !!taskArchive,
      awaiting: taskAwaiting,
    };
  }, [taskParams, filterQuery, orgId, taskArchive, isConsultant, userId]);

  const { currentData: tasks, isFetching: taskFetch } = useGetTasksQuery(
    params,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const taskRowClick = useCallback(
    async ({ row }: { row: GetTasksApiResponse["rows"][number] }) => {
      const id = row.id;
      if (id) {
        openTask(id);
      }
    },
    [query?.task, openTask],
  );

  const TasksTable = useMemo(
    () => (
      <ServerDataGrid
        rowCount={tasks?.count || 0}
        hideColumns={{
          id: false,
          reported_by: false,
          deleted_at: !!taskArchive,
          action_pending: !taskArchive,
          created_at: !taskArchive,
          requested_by_date: !taskArchive,
        }}
        updateHideColumns={!!taskArchive}
        rows={tasks?.rows ?? []}
        columns={tasksCols}
        setQueryParams={setTaskParams}
        toolbarProps={{ hideToolbar: true }}
        isFetching={taskFetch}
        getRowClassName={(params) => {
          const hasActiveSession = params.row?.has_active_session ?? false;
          const isPendingSession =
            hasActiveSession && !params.row?.session_start_time;
          if (isPendingSession) {
            return `table-css-session-status--pending`;
          }
          return `table-css-priority--${params.row.priority}`;
        }}
        props={{
          onRowClick: taskRowClick,
          density: "compact",
          sx: {
            "& .MuiDataGrid-columnHeaders": {
              borderTop: `0.5px solid ${palette.grey["300"]}`,
            },
            ".MuiDataGrid-columnHeaderTitle": {
              textTransform: "uppercase",
            },
            ".MuiDataGrid-row": {
              cursor: "pointer",
            },
            ".table-css-priority--critical": {
              backgroundColor: "#FACCD1",
            },
            ".table-css-priority--critical.Mui-hovered, .table-css-priority--critical.Mui-selected, .table-css-priority--critical.Mui-hovered.Mui-selected":
              {
                backgroundColor: "#F8ADB7",
              },
            ".table-css-session-status--pending": {
              backgroundColor: palette.grey[200],
            },
            ".table-css-session-status--pending:hover": {
              backgroundColor: palette.grey[300],
            },
            ".MuiDataGrid-cell:last-of-type": {
              maxWidth: "100% !important",
            },
          },
        }}
        currentParams={taskParams}
      />
    ),
    [
      tasks,
      tasksCols,
      setTaskParams,
      taskRowClick,
      taskParams,
      taskFetch,
      isPageSubSection,
      taskArchive,
    ],
  );

  return (
    <Stack
      sx={{
        height,
        minHeight: height,
        mb: 2,
        width: "100%",
        borderRadius: "6px",
        boxShadow: "none",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: palette.grey[500],
        backgroundColor: "#FFF",
      }}
    >
      <Stack direction={"column"}>
        <Stack direction={"row"} pt={2} alignItems={"center"}>
          <Typography variant="h3" pl={2}>
            TASKS
          </Typography>
          <ToggleDropdown
            option1="active"
            option2="archived"
            setValue={(val) =>
              setQuery({ taskArchive: val === "archived" || undefined })
            }
            value={taskArchive ? "archived" : "active"}
          />
        </Stack>
        {!taskArchive && (
          <TaskFilters isTable inOrgView={inOrgView} orgId={orgId || -1} />
        )}
      </Stack>

      {TasksTable}
    </Stack>
  );
};
