import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Stack,
  TextField,
  Button,
  Typography,
  InputAdornment,
  Box,
} from "@mui/material";
import { Formik, Form } from "formik";
import { Navigation, yup } from "../../lib";

import { useDispatch, useSelector } from "react-redux";
import { authSelectors, clientActions, clientSelectors } from "../../state";
import { actions } from "../../state/rtk-query/state";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useCallback, useState } from "react";
import {
  DateRange,
  DateRangePicker,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { CalendarBlankIcon } from "../icons";
import { useMobile } from "../../themes";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useOrgId } from "../hooks";
const {
  useGetOrgByIdQuery,
  useGetCalendlyUserByOrgIdQuery,
  useGetSessionsByIdQuery,
  useRescheduleFromExistingSessionMutation,
  useCreateSessionMutation,
  useCreatePendingSessionMutation,
} = actions;

const validationSchema = yup.object({
  title: yup.string(),
});
interface Props {
  createPendingSubmit?: (id: number) => void;
  removeHeader?: boolean;
  session_id?: number;
  isRescheduling?: boolean;
  orgId?: number;
}
export const CreateSessionForm = ({
  createPendingSubmit,
  removeHeader = false,
  session_id,
  isRescheduling,
  orgId: _orgId,
}: Props) => {
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const [title, setTitle] = useState("");
  const [dateRange, setDateRange] = useState<DateRange<any>>();
  const [creationType, setCreationType] = useState<"new" | "pending">("new");
  const userInfo = useSelector(authSelectors.userInfo);
  const userId = useSelector(authSelectors.userId);
  const { screen } = useSelector(clientSelectors.currentSupportStep);
  const orgId = _orgId ?? useOrgId();
  const isInternal = useSelector(authSelectors.isInternal);

  const initialValues = {
    title: "",
  };
  const { currentData: org } = useGetOrgByIdQuery(
    orgId ? { orgId } : skipToken,
    { skip: isInternal, refetchOnMountOrArgChange: true },
  );

  const { currentData: consultantData } = useGetCalendlyUserByOrgIdQuery(
    orgId ? { orgId } : skipToken,
    { skip: !isInternal, refetchOnMountOrArgChange: true },
  );

  const { currentData: sessionInfo } = useGetSessionsByIdQuery(
    session_id && orgId ? { id: session_id, orgId } : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const [createSession] = useCreateSessionMutation();
  const [createPendingSessionMut] = useCreatePendingSessionMutation();
  const [rescheduleFromExisting] = useRescheduleFromExistingSessionMutation();
  const assignee = isInternal
    ? sessionInfo?.assigned_to_user.id ?? userId
    : org?.consultant?.id;
  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      if (((org?.consultant?.id && org?.id) || isInternal) && assignee) {
        let sessionCreated: { id?: number } = {};

        if (session_id && isRescheduling) {
          sessionCreated = await rescheduleFromExisting({
            id: session_id,
            orgId,
            body: {
              assigned_to: assignee,
              title,
              ...e.data.payload,
            },
          }).unwrap();
        } else if (!isRescheduling) {
          sessionCreated = await createSession({
            orgId,
            body: {
              session_id,
              assigned_to: assignee,
              title,
              ...e.data.payload,
            },
          }).unwrap();
        }

        if (sessionCreated?.id) {
          if (isRescheduling) {
            Navigation.go(`/sessions/${sessionCreated.id}`);
          }
          dispatch(clientActions.setSupportSessionId(sessionCreated.id));
        }

        dispatch(clientActions.setSupportScreenAction("Done"));
      }
    },
  });

  const radioClick = useCallback((type: "new" | "pending") => {
    if (type === "pending") {
      dispatch(clientActions.setSupportScreenAction("CreatePendingSession"));
    } else {
      dispatch(clientActions.setSupportScreenAction("CreateSession"));
    }
    setCreationType(type);
  }, []);

  const onTitleChange = useCallback((title: string) => {
    setTitle(title);
  }, []);

  const onCalendarChange = useCallback((values: DateRange<any>) => {
    if (values?.filter(Boolean).length === 2) {
      setDateRange(values);
    }
  }, []);
  const consultantId = isInternal
    ? sessionInfo?.created_by_user.id ?? userId
    : org?.consultant?.id;
  const createPendingSession = useCallback(async () => {
    if (consultantId && dateRange) {
      const sessionCreated = await createPendingSessionMut({
        orgId: org?.id ?? orgId,
        body: {
          assigned_to: consultantId,
          title,
          pending_session_dates: dateRange,
        },
      }).unwrap();

      if (createPendingSubmit && sessionCreated?.id) {
        createPendingSubmit(sessionCreated.id);
      }
    }
  }, [dateRange, org, orgId, createPendingSubmit]);

  const prefillUser = isInternal
    ? sessionInfo?.created_by_user ?? userInfo
    : userInfo;
  // 61.5% of the page seems to be a good sweet spot
  const calcHeightOfIframe = 0.615 * window.innerHeight;
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {() => (
        <Form>
          {!removeHeader ? (
            <>
              {screen !== "Done" ? (
                <Stack
                  display={"flex"}
                  px={creationType === "new" ? (isMobile ? "16px" : "50px") : 0}
                  mt={creationType === "new" || isMobile ? 1 : 0}
                  width={"100%"}
                >
                  <FormControl>
                    <RadioGroup
                      defaultValue="new"
                      name="creationType"
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                      }}
                    >
                      <FormControlLabel
                        value="new"
                        control={<Radio onClick={() => radioClick("new")} />}
                        label="Schedule session"
                      />

                      <FormControlLabel
                        value="pending"
                        control={
                          <Radio onClick={() => radioClick("pending")} />
                        }
                        label="Schedule pending session"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              ) : null}
              <Stack
                px={creationType === "new" ? (isMobile ? "16px" : "50px") : 0}
                width={"100%"}
                overflow={"hidden"}
              >
                <Typography fontSize={12} lineHeight={0} my={2}>
                  Session Name
                </Typography>
                <TextField
                  name="title"
                  onBlur={(e) => onTitleChange(e.target.value)}
                  disabled={screen === "Done"}
                />
              </Stack>
            </>
          ) : null}

          {creationType === "new" ? (
            <>
              {org?.personal_link || consultantData?.personal_link ? (
                <Box
                  maxHeight={removeHeader ? "max-content" : "min-content"}
                  overflow={"hidden"}
                  mt={1.5}
                >
                  <InlineWidget
                    url={
                      (isInternal
                        ? consultantData?.personal_link
                        : org?.personal_link) || ""
                    }
                    styles={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "stretch",
                      padding: 0,
                      marginTop: isMobile ? 0 : -40,
                      backgroundColor: "#FFF",
                      height: removeHeader
                        ? "650px"
                        : `${calcHeightOfIframe}px`,
                      boxShadow: "none",
                      outline: "none",
                      border: "none",
                      maxHeight: removeHeader ? 650 : calcHeightOfIframe,
                    }}
                    prefill={{
                      email: prefillUser?.email,
                      firstName: prefillUser?.first_name ?? "",
                      lastName: prefillUser?.last_name ?? "",
                      name: `${prefillUser?.first_name} ${prefillUser?.last_name}`,
                      customAnswers: {
                        a1:
                          (isInternal ? sessionInfo?.org?.name : org?.name) ??
                          "",
                      },
                    }}
                  />
                </Box>
              ) : null}
            </>
          ) : (
            <Stack display={"flex"} py={2} width={"100%"}>
              <Typography fontSize={12} lineHeight={0} my={2}>
                Date Range
              </Typography>

              <DateRangePicker
                slots={{
                  field: SingleInputDateRangeField,
                  textField: TextField,
                }}
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarBlankIcon />
                        </InputAdornment>
                      ),
                    },
                  },
                }}
                onChange={onCalendarChange}
              />

              <Stack direction={"row"} justifyContent="flex-end" my={2}>
                <Button
                  onClick={createPendingSession}
                  variant="contained"
                  sx={{ width: "fit-content" }}
                  disabled={
                    !dateRange ||
                    Boolean(dateRange?.filter((dr) => dr === null).length)
                  }
                >
                  Create Session
                </Button>
              </Stack>
            </Stack>
          )}
        </Form>
      )}
    </Formik>
  );
};
