import {
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useOpenTaskDrawer, useQueryParam } from "../../hooks";
import { useMobile } from "../../../themes";
import { useSelector } from "react-redux";
import { authSelectors, timerSelectors } from "../../../state";
import {
  GetSignedUrlByMediaIdApiResponse,
  useDeleteMediaMutation,
  useLazyGetSignedUrlByMediaIdQuery,
} from "../../../state/rtk-query/state/media";
import { GetOrgTaskMediaApiResponse } from "../../../state/rtk-query/state/tasks";
import { PreviewFileExtensions, formatBytes, formatDate } from "../../../lib";
import {
  OpenInNewIcon,
  MediaDownload,
  DeleteLinedIcon,
  CloseIcon,
  FileIcon,
  PictureAsPdfIcon,
  ChartIcon,
} from "../../icons";
import {
  ConfirmDeleteModal,
  EllipsisTypographyStyled,
  TextButtonWithIcon,
} from "../..";
import { useGetUserProfileQuery } from "../../../state/rtk-query/state/user";

type OrgTaskMedia = GetOrgTaskMediaApiResponse["rows"][number];
interface Props {
  orgId?: number;
  task?: OrgTaskMedia["task"];
  media: OrgTaskMedia["media"];
}

export const MediaDrawer = ({ orgId, media, task }: Props) => {
  const isMobile = useMobile();
  const { palette } = useTheme();
  const openDrawer = useOpenTaskDrawer();
  const [mediaId, setQuery] = useQueryParam<number>("media");
  const [drawerImg, setDrawerImg] = useState<
    GetSignedUrlByMediaIdApiResponse | undefined
  >();
  const signedUrl = drawerImg?.signedUrl;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [getMediaSignedUrl, { isFetching }] =
    useLazyGetSignedUrlByMediaIdQuery();
  const [deleteMedia] = useDeleteMediaMutation();

  const { currentData: userInfo } = useGetUserProfileQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const { orgs = [] } = userInfo || {};
  const inImpersonation = useSelector(authSelectors.inImpersonation);
  const isInternal = useSelector(authSelectors.isInternal);
  const { id: timerId } = useSelector(timerSelectors.currentTimer) ?? {};
  const hasMultiOrg = Boolean(orgs && orgs?.length > 1) && !isInternal;
  const getSignedUrl = useCallback(
    ({ download, pdfImg = true }: { download: boolean; pdfImg?: boolean }) => {
      if (!orgId) return;
      return getMediaSignedUrl({
        id: mediaId,
        download,
        orgId,
        pdfImg,
      }).unwrap();
    },
    [mediaId, orgId],
  );

  useEffect(() => {
    if (!mediaId) return;
    (async () => {
      const fullImg = await getSignedUrl({ download: false });

      if (fullImg) setDrawerImg(fullImg);
    })();
  }, [mediaId]);

  const closeDrawer = () => {
    setQuery(undefined);
  };

  const openImgInNewTab = async () => {
    if (
      signedUrl?.slice(0, signedUrl.indexOf("?")).endsWith(".jpg") &&
      drawerImg?.originalExt === ".pdf"
    ) {
      const pdf = await getSignedUrl({ download: false, pdfImg: false });
      window.open(pdf?.signedUrl);
    } else {
      window.open(signedUrl);
    }
  };

  const downloadFile = async () => {
    const fullImg = await getSignedUrl({ download: true, pdfImg: false });
    if (!fullImg) return;
    const link = document.createElement("a");
    link.href = fullImg?.signedUrl;
    link.click();
  };

  const deleteMediaClick = useCallback(
    async (mediaId: number) => {
      closeDrawer();
      if (mediaId && orgId) {
        await deleteMedia({ id: mediaId, orgId });
      }
    },
    [orgId, mediaId],
  );
  const openDeleteModalClick = () => {
    setOpenDeleteModal((o) => !o);
  };
  const openTaskClick = () => {
    if (task?.id) {
      openDrawer(task?.id);
    }
  };
  const ogExt = drawerImg?.originalExt?.toLowerCase() ?? "";
  const canPreview = PreviewFileExtensions.find((e) => e === ogExt);

  return (
    <Drawer
      anchor="right"
      open={!!mediaId}
      onClose={closeDrawer}
      sx={{
        width: isMobile ? "100%" : "35%",
        height: `calc(100vh + ${timerId ? "48px" : 0})`,
        "& .MuiDrawer-paper": {
          width: isMobile ? "100%" : "35%",
          marginTop:
            (timerId || hasMultiOrg) && !inImpersonation && !isMobile
              ? "48px"
              : 0,
        },
      }}
    >
      <Stack direction={"column"} m={4}>
        <Stack direction={"row"} justifyContent={"flex-end"} mb={1}>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Paper
          elevation={6}
          sx={{
            width: "100%",
            height: "fit-content",
            maxHeight: "70vh",
            p: 1,
          }}
        >
          {!isFetching ? (
            canPreview ? (
              <img
                src={drawerImg?.signedUrl}
                style={{
                  width: "auto",
                  height: "auto",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                  objectPosition: "center",
                }}
              />
            ) : (
              <Stack
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
                height={"150px"}
              >
                {ogExt === ".pdf" ? (
                  <PictureAsPdfIcon />
                ) : [".csv", ".xls", ".xlsx"].includes(ogExt) ? (
                  <ChartIcon size={60} color={palette.success[700]} />
                ) : (
                  <FileIcon size={60} />
                )}
              </Stack>
            )
          ) : (
            <Skeleton width={"300px"} height={"300px"} />
          )}
        </Paper>
        <Typography variant="h3" mt={4} mb={2}>
          {media?.filename}
        </Typography>
        <Grid container>
          <Grid item xs={3} xl={2}>
            <Typography variant="subtitle1">Size</Typography>
          </Grid>
          <Grid item xs={9} xl={10}>
            <Typography variant="subtitle1">
              {formatBytes(media?.size ?? 0)}
            </Typography>
          </Grid>
          <Grid item xs={3} xl={2} display={"flex"} alignItems={"center"}>
            <Typography variant="subtitle1">Task</Typography>
          </Grid>
          <Grid item xs={9} xl={10} display={"flex"} alignItems={"center"}>
            <Button
              sx={{ fontWeight: 500, p: 0, m: 0 }}
              color={"primary"}
              onClick={openTaskClick}
            >
              <EllipsisTypographyStyled>
                {`[${task?.code ?? ""}] - ${task?.title ?? ""}`}
              </EllipsisTypographyStyled>
            </Button>
          </Grid>
          {!!media?.created_at && (
            <>
              <Grid item xs={3} xl={2}>
                <Typography variant="subtitle1">Uploaded</Typography>
              </Grid>
              <Grid item xs={9} xl={10}>
                <Typography variant="subtitle1">
                  {formatDate(
                    new Date(media?.created_at),
                    "MM/dd/yyyy h:mm aa",
                  )}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Stack
          direction={"row"}
          justifyContent={"center"}
          mb={4}
          alignItems={"flex-end"}
        >
          {canPreview ? (
            <TextButtonWithIcon
              onClick={openImgInNewTab}
              Icon={OpenInNewIcon}
              text="View"
            />
          ) : null}
          <TextButtonWithIcon
            onClick={downloadFile}
            Icon={MediaDownload}
            text="Download"
          />
          <TextButtonWithIcon
            onClick={openDeleteModalClick}
            Icon={DeleteLinedIcon}
            text="Delete"
            buttonColor="error"
          />
          {mediaId && media?.filename && (
            <ConfirmDeleteModal
              open={!!openDeleteModal}
              onClose={openDeleteModalClick}
              handleConfirm={() => deleteMediaClick(mediaId ?? 0)}
              titleText={`Are you sure you want to delete ${media.filename}`}
            />
          )}
        </Stack>
      </Stack>
    </Drawer>
  );
};
