import { api } from "../api";
export const addTagTypes = [
  "sessions",
  "tasks",
  "notifications",
  "time_entry",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getSessions: build.query<GetSessionsApiResponse, GetSessionsApiArg>({
        query: (queryArg) => ({
          url: `/sessions`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
            custom: queryArg.custom,
            archived: queryArg.archived,
          },
        }),
        providesTags: ["sessions", "tasks"],
      }),
      createPendingSession: build.mutation<
        CreatePendingSessionApiResponse,
        CreatePendingSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/sessions/pending`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["sessions"],
      }),
      getSession: build.query<GetSessionApiResponse, GetSessionApiArg>({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/sessions/${queryArg.id}`,
        }),
        providesTags: ["sessions", "tasks"],
      }),
      rescheduleFromExistingSession: build.mutation<
        RescheduleFromExistingSessionApiResponse,
        RescheduleFromExistingSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/sessions/${queryArg.id}`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["sessions", "notifications"],
      }),
      updateSession: build.mutation<
        UpdateSessionApiResponse,
        UpdateSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/sessions/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["sessions"],
      }),
      deleteSession: build.mutation<
        DeleteSessionApiResponse,
        DeleteSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/sessions/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["sessions"],
      }),
      updateSessionStatus: build.mutation<
        UpdateSessionStatusApiResponse,
        UpdateSessionStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/sessions/${queryArg.id}/status`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["sessions"],
      }),
      cancelSession: build.mutation<
        CancelSessionApiResponse,
        CancelSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/sessions/${queryArg.id}/cancel`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["sessions", "notifications"],
      }),
      restoreSession: build.mutation<
        RestoreSessionApiResponse,
        RestoreSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/sessions/${queryArg.id}/restore`,
          method: "POST",
        }),
        invalidatesTags: ["sessions", "notifications"],
      }),
      markSessionNoShow: build.mutation<
        MarkSessionNoShowApiResponse,
        MarkSessionNoShowApiArg
      >({
        query: (queryArg) => ({
          url: `/sessions/${queryArg.id}/mark-no-show`,
          method: "POST",
        }),
        invalidatesTags: ["sessions"],
      }),
      getTimeEntries: build.query<
        GetTimeEntriesApiResponse,
        GetTimeEntriesApiArg
      >({
        query: (queryArg) => ({ url: `/sessions/${queryArg.id}/time-entries` }),
        providesTags: ["sessions", "time_entry"],
      }),
      createTimeEntry: build.mutation<
        CreateTimeEntryApiResponse,
        CreateTimeEntryApiArg
      >({
        query: (queryArg) => ({
          url: `/sessions/${queryArg.id}/time-entries`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["sessions", "time_entry"],
      }),
      getTimeEntry: build.query<GetTimeEntryApiResponse, GetTimeEntryApiArg>({
        query: (queryArg) => ({ url: `/sessions/time-entry/${queryArg.id}` }),
        providesTags: ["sessions", "time_entry"],
      }),
      updateTimeEntry: build.mutation<
        UpdateTimeEntryApiResponse,
        UpdateTimeEntryApiArg
      >({
        query: (queryArg) => ({
          url: `/sessions/time-entry/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["sessions", "time_entry"],
      }),
      deleteTimeEntry: build.mutation<
        DeleteTimeEntryApiResponse,
        DeleteTimeEntryApiArg
      >({
        query: (queryArg) => ({
          url: `/sessions/time-entry/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["sessions", "time_entry"],
      }),
      getSessionsByOrgId: build.query<
        GetSessionsByOrgIdApiResponse,
        GetSessionsByOrgIdApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/sessions`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
            custom: queryArg.custom,
          },
        }),
        providesTags: ["sessions", "tasks"],
      }),
      getUpcomingSessions: build.query<
        GetUpcomingSessionsApiResponse,
        GetUpcomingSessionsApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/upcoming-sessions`,
          params: { include_pending: queryArg.includePending },
        }),
        providesTags: ["sessions", "tasks"],
      }),
      getTodaysSessions: build.query<
        GetTodaysSessionsApiResponse,
        GetTodaysSessionsApiArg
      >({
        query: () => ({ url: `/sessions/mine/today` }),
        providesTags: ["sessions", "tasks"],
      }),
      getSessionsById: build.query<
        GetSessionsByIdApiResponse,
        GetSessionsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/client/sessions/${queryArg.id}`,
        }),
        providesTags: ["sessions", "tasks"],
      }),
      updateSessionTitle: build.mutation<
        UpdateSessionTitleApiResponse,
        UpdateSessionTitleApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/client/sessions/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["sessions"],
      }),
      createSession: build.mutation<
        CreateSessionApiResponse,
        CreateSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/client/sessions`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["sessions", "notifications"],
      }),
      getOrgSessionActivities: build.query<
        GetOrgSessionActivitiesApiResponse,
        GetOrgSessionActivitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/sessions/${queryArg.id}/activities`,
        }),
        providesTags: ["sessions", "tasks"],
      }),
      getSessionTasks: build.query<
        GetSessionTasksApiResponse,
        GetSessionTasksApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/sessions/${queryArg.id}/tasks`,
        }),
        providesTags: ["sessions", "tasks"],
      }),
      getSessionActivities: build.query<
        GetSessionActivitiesApiResponse,
        GetSessionActivitiesApiArg
      >({
        query: (queryArg) => ({ url: `/sessions/${queryArg.id}/activities` }),
        providesTags: ["sessions"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetSessionsApiResponse =
  /** status 200 Session response for clients */ {
    count: number;
    rows: {
      id?: number;
      org_id?: number;
      /** organizations */
      org?: {
        id: number;
        pipedrive_org_id?: number | null;
        name: string;
        phone?: string;
        country_code?: string | null;
        website?: string;
        address?: string;
        consultant_id?: number | null;
        sub_consultant_id?: number | null;
        account_manager_id?: number | null;
        license_status_id?: number | null;
        it_info?: string | null;
        internal_notes?: string | null;
        code?: string;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
        quickbooks_customer_id?: string | null;
      };
      status_id: number;
      title?: string | null;
      pending_session_dates?:
        | {
            value?: string | null;
            inclusive?: boolean | null;
          }[]
        | null;
      created_by_user: {
        id?: number;
        email: string;
        phone?: string | null;
        first_name: string;
        last_name: string;
        [key: string]: any;
      } & {
        roles?:
          | {
              internal_name?: string;
            }[]
          | null;
      };
      client_assignee_user?: {
        id?: number;
        email?: string;
        phone?: string | null;
        first_name?: string;
        last_name?: string;
        roles?:
          | {
              internal_name?: string;
            }[]
          | null;
      } | null;
      assigned_to_user: {
        id?: number;
        email: string;
        phone?: string | null;
        first_name: string;
        last_name: string;
        [key: string]: any;
      } & {
        roles?:
          | {
              internal_name?: string;
            }[]
          | null;
      };
      session_invitees?: {
        id: number;
        session_id: number;
        user_id?: number | null;
        email: string;
        user?:
          | ({
              id?: number;
              email: string;
              phone?: string | null;
              first_name: string;
              last_name: string;
              [key: string]: any;
            } & {
              roles?:
                | {
                    internal_name?: string;
                  }[]
                | null;
            })[]
          | null;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      /** booking */
      booking?: {
        id: number;
        session_id: number;
        name?: string | null;
        start_time: string;
        end_time?: string | null;
        event_uri: string | null;
        join_url: string;
        duration?: number | null;
        meeting_id?: string | null;
        reschedule_url?: string;
        rescheduled?: boolean;
        reason?: string | null;
        play_url?: string | null;
        download_url?: string | null;
        meeting_password?: string | null;
        booking_engine: "calendly" | "other";
        /** booking_types */
        booking_type?: {
          id: number;
          active: boolean;
          admin_managed: boolean;
          booking_method?: string | null;
          color?: string | null;
          description_html?: string | null;
          description_plain?: string | null;
          duration: number;
          internal_note?: string | null;
          kind: string;
          name: string;
          pooling_type?: string | null;
          scheduling_url: string;
          secret: boolean;
          slug?: string | null;
          type: string;
          created_at?: string | null;
          updated_at?: string | null;
          deleted_at?: string | null;
        };
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      } | null;
      tasks?: {
        id?: number;
        org_id?: number;
        code?: string;
        /** statuses schema */
        status?: {
          id?: number;
          internal_name?: string;
          name?: string;
          description?: string;
          type?:
            | (
                | "all"
                | "session"
                | "all_tasks"
                | "task"
                | "internal_task"
                | "custom_work"
                | "quick_question"
                | null
              )
            | null;
          is_automated?: boolean | null;
          transition_phase?:
            | "todo"
            | "in_progress"
            | "action_required"
            | "done"
            | "blocked";
          color?:
            | "green"
            | "red"
            | "yellow"
            | "grey"
            | "primary"
            | "tertiary"
            | "secondary"
            | "lightGrey";
          created_at?: string;
          updated_at?: string | null;
          deleted_at?: string | null;
        };
        sessions?:
          | {
              id?: number;
              org_id: number;
              created_by: number;
              client_assignee?: number | null;
              title?: string | null;
              assigned_to?: number;
              status_id: number;
              pending_session_dates?:
                | {
                    value?: string | null;
                    inclusive?: boolean | null;
                  }[]
                | null;
              session_tasks?: {
                is_active?: boolean | null;
              } | null;
              created_at?: string | null;
              updated_at?: string | null;
              deleted_at?: string | null;
            }[]
          | null;
        media?: ({
          id?: number;
          path?: string;
          filename?: string | null;
          internal_name?: string;
          label?: string;
          mime_type?: string | null;
          size?: number | null;
          status_id?: number | null;
          status?: string | null;
          uploaded_by?: number;
          created_at?: string | null;
          [key: string]: any;
        } & {
          uploaded_by_user?: {
            id?: number;
            email: string;
            phone?: string | null;
            first_name: string;
            last_name: string;
          };
        } & ({
            task_media?: {
              is_custom_work_manual?: boolean | null;
            };
          } | null))[];
        /** topics */
        topic?: {
          id?: number;
          internal_name: string;
          name: string;
          description: string;
          created_at?: string | null;
          updated_at?: string | null;
          deleted_at?: string | null;
        };
        task_chat_count?: number;
        watchers?:
          | {
              id?: number;
              email: string;
              phone?: string | null;
              first_name: string;
              last_name: string;
            }[]
          | null;
        title: string;
        description?: string | null;
        type?: "task" | "quick_question" | "internal_task" | "custom_work";
        priority: "normal" | "high" | "critical";
        internal_priority?: "low" | "medium" | "high";
        assigned_to_user?: {
          id?: number;
          email: string;
          phone?: string | null;
          first_name: string;
          last_name: string;
          [key: string]: any;
        } & {
          roles?:
            | {
                internal_name?: string;
              }[]
            | null;
        };
        reported_by_user?: {
          id?: number;
          email: string;
          phone?: string | null;
          first_name: string;
          last_name: string;
          [key: string]: any;
        } & {
          roles?:
            | {
                internal_name?: string;
              }[]
            | null;
        };
        client_assignee_user?: {
          id?: number;
          email?: string;
          phone?: string | null;
          first_name?: string;
          last_name?: string;
          roles?:
            | {
                internal_name?: string;
              }[]
            | null;
        } | null;
        requested_by_date?: string | null;
        description_search?: string | null;
        session_tasks?: {
          is_active?: boolean | null;
        } | null;
        billing_status?: ("no_charge" | "making_standard" | null) | null;
        action_pending?: ("admire" | "client" | null) | null;
        instant_meeting_url?: string | null;
        instant_meeting_occurred?: boolean | null;
        linkedTasks?:
          | {
              thisTaskId: number;
              linkedTaskId: number;
              linkTypeId: number;
              linkType?: string | null;
              /** tasks schema */
              linkedTask: {
                id?: number;
                status_id?: number;
                topic_id?: number;
                org_id?: number;
                title?: string;
                description?: string | null;
                priority?: "normal" | "high" | "critical";
                internal_priority?: "low" | "medium" | "high";
                assigned_to?: number;
                reported_by?: number;
                type?:
                  | "task"
                  | "quick_question"
                  | "internal_task"
                  | "custom_work";
                requested_by_date?: string | null;
                instant_meeting_url?: string | null;
                instant_meeting_occurred?: boolean | null;
                client_assignee?: number | null;
                billing_status?:
                  | ("no_charge" | "making_standard" | null)
                  | null;
                description_search?: string | null;
                action_pending?: ("admire" | "client" | null) | null;
                code?: string | null;
                allow_org_change?: boolean;
                created_at?: string;
                updated_at?: string | null;
                deleted_at?: string | null;
              };
              originalRecord?: any;
            }[]
          | null;
        allow_org_change?: boolean;
        time_spent?: number | null;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
  };
export type GetSessionsApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
  /** custom filters that can be represented by a stringified object */
  custom?: string;
  /** get all archived */
  archived?: boolean;
};
export type CreatePendingSessionApiResponse = /** status 200 id */ {
  id: number;
};
export type CreatePendingSessionApiArg = {
  /** organization id */
  orgId: number;
  /** sessions */
  body: {
    id?: number;
    title?: string | null;
    session_id?: number | null;
    created_by?: number;
    client_assignee?: number;
    assigned_to: number;
    pending_session_dates?: (string | null)[] | null;
    event?: {
      uri?: string;
    };
    invitee?: {
      uri?: string;
    };
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
};
export type GetSessionApiResponse = /** status 200 Show single Session */ {
  id?: number;
  title?: string | null;
  /** organizations */
  org?: {
    id: number;
    pipedrive_org_id?: number | null;
    name: string;
    phone?: string;
    country_code?: string | null;
    website?: string;
    address?: string;
    consultant_id?: number | null;
    sub_consultant_id?: number | null;
    account_manager_id?: number | null;
    license_status_id?: number | null;
    it_info?: string | null;
    internal_notes?: string | null;
    code?: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
    quickbooks_customer_id?: string | null;
  };
  status_id: number;
  created_by_user: {
    id?: number;
    email: string;
    phone?: string | null;
    first_name: string;
    last_name: string;
    [key: string]: any;
  } & {
    roles?:
      | {
          internal_name?: string;
        }[]
      | null;
  };
  client_assignee_user?: {
    id?: number;
    email?: string;
    phone?: string | null;
    first_name?: string;
    last_name?: string;
    roles?:
      | {
          internal_name?: string;
        }[]
      | null;
  } | null;
  assigned_to_user: {
    id?: number;
    email: string;
    phone?: string | null;
    first_name: string;
    last_name: string;
    [key: string]: any;
  } & {
    roles?:
      | {
          internal_name?: string;
        }[]
      | null;
  };
  session_invitees?: {
    id: number;
    session_id: number;
    user_id?: number | null;
    email: string;
    user?:
      | ({
          id?: number;
          email: string;
          phone?: string | null;
          first_name: string;
          last_name: string;
          [key: string]: any;
        } & {
          roles?:
            | {
                internal_name?: string;
              }[]
            | null;
        })[]
      | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
  /** booking */
  booking?: {
    id: number;
    session_id: number;
    name?: string | null;
    start_time: string;
    end_time?: string | null;
    event_uri: string | null;
    join_url: string;
    duration?: number | null;
    meeting_id?: string | null;
    reschedule_url?: string;
    rescheduled?: boolean;
    reason?: string | null;
    play_url?: string | null;
    download_url?: string | null;
    meeting_password?: string | null;
    booking_engine: "calendly" | "other";
    /** booking_types */
    booking_type?: {
      id: number;
      active: boolean;
      admin_managed: boolean;
      booking_method?: string | null;
      color?: string | null;
      description_html?: string | null;
      description_plain?: string | null;
      duration: number;
      internal_note?: string | null;
      kind: string;
      name: string;
      pooling_type?: string | null;
      scheduling_url: string;
      secret: boolean;
      slug?: string | null;
      type: string;
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
    };
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  } | null;
  session_time_entries?: {
    id: number;
    session_id: number;
    start_time: string;
    end_time?: string | null;
    minutes_worked: number;
    note?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
  tasks?: {
    id?: number;
    org_id?: number;
    code?: string;
    /** statuses schema */
    status?: {
      id?: number;
      internal_name?: string;
      name?: string;
      description?: string;
      type?:
        | (
            | "all"
            | "session"
            | "all_tasks"
            | "task"
            | "internal_task"
            | "custom_work"
            | "quick_question"
            | null
          )
        | null;
      is_automated?: boolean | null;
      transition_phase?:
        | "todo"
        | "in_progress"
        | "action_required"
        | "done"
        | "blocked";
      color?:
        | "green"
        | "red"
        | "yellow"
        | "grey"
        | "primary"
        | "tertiary"
        | "secondary"
        | "lightGrey";
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    };
    sessions?:
      | {
          id?: number;
          org_id: number;
          created_by: number;
          client_assignee?: number | null;
          title?: string | null;
          assigned_to?: number;
          status_id: number;
          pending_session_dates?:
            | {
                value?: string | null;
                inclusive?: boolean | null;
              }[]
            | null;
          session_tasks?: {
            is_active?: boolean | null;
          } | null;
          created_at?: string | null;
          updated_at?: string | null;
          deleted_at?: string | null;
        }[]
      | null;
    media?: ({
      id?: number;
      path?: string;
      filename?: string | null;
      internal_name?: string;
      label?: string;
      mime_type?: string | null;
      size?: number | null;
      status_id?: number | null;
      status?: string | null;
      uploaded_by?: number;
      created_at?: string | null;
      [key: string]: any;
    } & {
      uploaded_by_user?: {
        id?: number;
        email: string;
        phone?: string | null;
        first_name: string;
        last_name: string;
      };
    } & ({
        task_media?: {
          is_custom_work_manual?: boolean | null;
        };
      } | null))[];
    /** topics */
    topic?: {
      id?: number;
      internal_name: string;
      name: string;
      description: string;
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
    };
    task_chat_count?: number;
    watchers?:
      | {
          id?: number;
          email: string;
          phone?: string | null;
          first_name: string;
          last_name: string;
        }[]
      | null;
    title: string;
    description?: string | null;
    type?: "task" | "quick_question" | "internal_task" | "custom_work";
    priority: "normal" | "high" | "critical";
    internal_priority?: "low" | "medium" | "high";
    assigned_to_user?: {
      id?: number;
      email: string;
      phone?: string | null;
      first_name: string;
      last_name: string;
      [key: string]: any;
    } & {
      roles?:
        | {
            internal_name?: string;
          }[]
        | null;
    };
    reported_by_user?: {
      id?: number;
      email: string;
      phone?: string | null;
      first_name: string;
      last_name: string;
      [key: string]: any;
    } & {
      roles?:
        | {
            internal_name?: string;
          }[]
        | null;
    };
    client_assignee_user?: {
      id?: number;
      email?: string;
      phone?: string | null;
      first_name?: string;
      last_name?: string;
      roles?:
        | {
            internal_name?: string;
          }[]
        | null;
    } | null;
    requested_by_date?: string | null;
    description_search?: string | null;
    session_tasks?: {
      is_active?: boolean | null;
    } | null;
    billing_status?: ("no_charge" | "making_standard" | null) | null;
    action_pending?: ("admire" | "client" | null) | null;
    instant_meeting_url?: string | null;
    instant_meeting_occurred?: boolean | null;
    linkedTasks?:
      | {
          thisTaskId: number;
          linkedTaskId: number;
          linkTypeId: number;
          linkType?: string | null;
          /** tasks schema */
          linkedTask: {
            id?: number;
            status_id?: number;
            topic_id?: number;
            org_id?: number;
            title?: string;
            description?: string | null;
            priority?: "normal" | "high" | "critical";
            internal_priority?: "low" | "medium" | "high";
            assigned_to?: number;
            reported_by?: number;
            type?: "task" | "quick_question" | "internal_task" | "custom_work";
            requested_by_date?: string | null;
            instant_meeting_url?: string | null;
            instant_meeting_occurred?: boolean | null;
            client_assignee?: number | null;
            billing_status?: ("no_charge" | "making_standard" | null) | null;
            description_search?: string | null;
            action_pending?: ("admire" | "client" | null) | null;
            code?: string | null;
            allow_org_change?: boolean;
            created_at?: string;
            updated_at?: string | null;
            deleted_at?: string | null;
          };
          originalRecord?: any;
        }[]
      | null;
    allow_org_change?: boolean;
    time_spent?: number | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
  pending_session_dates?:
    | {
        value?: string | null;
        inclusive?: boolean | null;
      }[]
    | null;
  created_at?: string | null;
  updated_at?: string | null;
  deleted_at?: string | null;
};
export type GetSessionApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type RescheduleFromExistingSessionApiResponse = /** status 200 id */ {
  id: number;
};
export type RescheduleFromExistingSessionApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** sessions */
  body: {
    id?: number;
    title?: string | null;
    session_id?: number | null;
    created_by?: number;
    client_assignee?: number;
    assigned_to: number;
    pending_session_dates?: (string | null)[] | null;
    event?: {
      uri?: string;
    };
    invitee?: {
      uri?: string;
    };
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
};
export type UpdateSessionApiResponse = /** status 204 An empty response */ void;
export type UpdateSessionApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** sessions */
  body: {
    id?: number;
    title?: string | null;
    session_id?: number | null;
    created_by?: number;
    client_assignee?: number;
    assigned_to: number;
    pending_session_dates?: (string | null)[] | null;
    event?: {
      uri?: string;
    };
    invitee?: {
      uri?: string;
    };
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
};
export type DeleteSessionApiResponse = /** status 204 An empty response */ void;
export type DeleteSessionApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type UpdateSessionStatusApiResponse =
  /** status 204 An empty response */ void;
export type UpdateSessionStatusApiArg = {
  /** record id */
  id: number;
  /** update status */
  body: {
    status_id: number;
  };
};
export type CancelSessionApiResponse = /** status 204 An empty response */ void;
export type CancelSessionApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** role */
  body: {
    reason: string;
  };
};
export type RestoreSessionApiResponse =
  /** status 204 An empty response */ void;
export type RestoreSessionApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type MarkSessionNoShowApiResponse =
  /** status 204 An empty response */ void;
export type MarkSessionNoShowApiArg = {
  /** record id */
  id: number;
};
export type GetTimeEntriesApiResponse = /** status 200 SessionTimeEntries */ {
  count: number;
  rows: {
    id: number;
    session_id: number;
    start_time: string;
    end_time?: string | null;
    minutes_worked: number;
    note?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetTimeEntriesApiArg = {
  /** record id */
  id: number;
};
export type CreateTimeEntryApiResponse =
  /** status 204 An empty response */ void;
export type CreateTimeEntryApiArg = {
  /** record id */
  id: number;
  /** session_time_entry */
  body: {
    id?: number;
    session_id?: number;
    start_time: string;
    end_time?: string | null;
    minutes_worked?: number;
    note?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
};
export type GetTimeEntryApiResponse =
  /** status 200 Show single SessionTimeEntry */ {
    id: number;
    session_id: number;
    start_time: string;
    end_time?: string | null;
    minutes_worked: number;
    note?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
export type GetTimeEntryApiArg = {
  /** record id */
  id: number;
};
export type UpdateTimeEntryApiResponse =
  /** status 204 An empty response */ void;
export type UpdateTimeEntryApiArg = {
  /** record id */
  id: number;
  /** session_time_entry */
  body: {
    id?: number;
    session_id?: number;
    start_time: string;
    end_time?: string | null;
    minutes_worked?: number;
    note?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
};
export type DeleteTimeEntryApiResponse =
  /** status 204 An empty response */ void;
export type DeleteTimeEntryApiArg = {
  /** record id */
  id: number;
};
export type GetSessionsByOrgIdApiResponse =
  /** status 200 Session response for clients */ {
    count: number;
    rows: {
      id?: number;
      org_id?: number;
      /** organizations */
      org?: {
        id: number;
        pipedrive_org_id?: number | null;
        name: string;
        phone?: string;
        country_code?: string | null;
        website?: string;
        address?: string;
        consultant_id?: number | null;
        sub_consultant_id?: number | null;
        account_manager_id?: number | null;
        license_status_id?: number | null;
        it_info?: string | null;
        internal_notes?: string | null;
        code?: string;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
        quickbooks_customer_id?: string | null;
      };
      status_id: number;
      title?: string | null;
      pending_session_dates?:
        | {
            value?: string | null;
            inclusive?: boolean | null;
          }[]
        | null;
      created_by_user: {
        id?: number;
        email: string;
        phone?: string | null;
        first_name: string;
        last_name: string;
        [key: string]: any;
      } & {
        roles?:
          | {
              internal_name?: string;
            }[]
          | null;
      };
      client_assignee_user?: {
        id?: number;
        email?: string;
        phone?: string | null;
        first_name?: string;
        last_name?: string;
        roles?:
          | {
              internal_name?: string;
            }[]
          | null;
      } | null;
      assigned_to_user: {
        id?: number;
        email: string;
        phone?: string | null;
        first_name: string;
        last_name: string;
        [key: string]: any;
      } & {
        roles?:
          | {
              internal_name?: string;
            }[]
          | null;
      };
      session_invitees?: {
        id: number;
        session_id: number;
        user_id?: number | null;
        email: string;
        user?:
          | ({
              id?: number;
              email: string;
              phone?: string | null;
              first_name: string;
              last_name: string;
              [key: string]: any;
            } & {
              roles?:
                | {
                    internal_name?: string;
                  }[]
                | null;
            })[]
          | null;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      /** booking */
      booking?: {
        id: number;
        session_id: number;
        name?: string | null;
        start_time: string;
        end_time?: string | null;
        event_uri: string | null;
        join_url: string;
        duration?: number | null;
        meeting_id?: string | null;
        reschedule_url?: string;
        rescheduled?: boolean;
        reason?: string | null;
        play_url?: string | null;
        download_url?: string | null;
        meeting_password?: string | null;
        booking_engine: "calendly" | "other";
        /** booking_types */
        booking_type?: {
          id: number;
          active: boolean;
          admin_managed: boolean;
          booking_method?: string | null;
          color?: string | null;
          description_html?: string | null;
          description_plain?: string | null;
          duration: number;
          internal_note?: string | null;
          kind: string;
          name: string;
          pooling_type?: string | null;
          scheduling_url: string;
          secret: boolean;
          slug?: string | null;
          type: string;
          created_at?: string | null;
          updated_at?: string | null;
          deleted_at?: string | null;
        };
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      } | null;
      tasks?: {
        id?: number;
        org_id?: number;
        code?: string;
        /** statuses schema */
        status?: {
          id?: number;
          internal_name?: string;
          name?: string;
          description?: string;
          type?:
            | (
                | "all"
                | "session"
                | "all_tasks"
                | "task"
                | "internal_task"
                | "custom_work"
                | "quick_question"
                | null
              )
            | null;
          is_automated?: boolean | null;
          transition_phase?:
            | "todo"
            | "in_progress"
            | "action_required"
            | "done"
            | "blocked";
          color?:
            | "green"
            | "red"
            | "yellow"
            | "grey"
            | "primary"
            | "tertiary"
            | "secondary"
            | "lightGrey";
          created_at?: string;
          updated_at?: string | null;
          deleted_at?: string | null;
        };
        sessions?:
          | {
              id?: number;
              org_id: number;
              created_by: number;
              client_assignee?: number | null;
              title?: string | null;
              assigned_to?: number;
              status_id: number;
              pending_session_dates?:
                | {
                    value?: string | null;
                    inclusive?: boolean | null;
                  }[]
                | null;
              session_tasks?: {
                is_active?: boolean | null;
              } | null;
              created_at?: string | null;
              updated_at?: string | null;
              deleted_at?: string | null;
            }[]
          | null;
        media?: ({
          id?: number;
          path?: string;
          filename?: string | null;
          internal_name?: string;
          label?: string;
          mime_type?: string | null;
          size?: number | null;
          status_id?: number | null;
          status?: string | null;
          uploaded_by?: number;
          created_at?: string | null;
          [key: string]: any;
        } & {
          uploaded_by_user?: {
            id?: number;
            email: string;
            phone?: string | null;
            first_name: string;
            last_name: string;
          };
        } & ({
            task_media?: {
              is_custom_work_manual?: boolean | null;
            };
          } | null))[];
        /** topics */
        topic?: {
          id?: number;
          internal_name: string;
          name: string;
          description: string;
          created_at?: string | null;
          updated_at?: string | null;
          deleted_at?: string | null;
        };
        task_chat_count?: number;
        watchers?:
          | {
              id?: number;
              email: string;
              phone?: string | null;
              first_name: string;
              last_name: string;
            }[]
          | null;
        title: string;
        description?: string | null;
        type?: "task" | "quick_question" | "internal_task" | "custom_work";
        priority: "normal" | "high" | "critical";
        internal_priority?: "low" | "medium" | "high";
        assigned_to_user?: {
          id?: number;
          email: string;
          phone?: string | null;
          first_name: string;
          last_name: string;
          [key: string]: any;
        } & {
          roles?:
            | {
                internal_name?: string;
              }[]
            | null;
        };
        reported_by_user?: {
          id?: number;
          email: string;
          phone?: string | null;
          first_name: string;
          last_name: string;
          [key: string]: any;
        } & {
          roles?:
            | {
                internal_name?: string;
              }[]
            | null;
        };
        client_assignee_user?: {
          id?: number;
          email?: string;
          phone?: string | null;
          first_name?: string;
          last_name?: string;
          roles?:
            | {
                internal_name?: string;
              }[]
            | null;
        } | null;
        requested_by_date?: string | null;
        description_search?: string | null;
        session_tasks?: {
          is_active?: boolean | null;
        } | null;
        billing_status?: ("no_charge" | "making_standard" | null) | null;
        action_pending?: ("admire" | "client" | null) | null;
        instant_meeting_url?: string | null;
        instant_meeting_occurred?: boolean | null;
        linkedTasks?:
          | {
              thisTaskId: number;
              linkedTaskId: number;
              linkTypeId: number;
              linkType?: string | null;
              /** tasks schema */
              linkedTask: {
                id?: number;
                status_id?: number;
                topic_id?: number;
                org_id?: number;
                title?: string;
                description?: string | null;
                priority?: "normal" | "high" | "critical";
                internal_priority?: "low" | "medium" | "high";
                assigned_to?: number;
                reported_by?: number;
                type?:
                  | "task"
                  | "quick_question"
                  | "internal_task"
                  | "custom_work";
                requested_by_date?: string | null;
                instant_meeting_url?: string | null;
                instant_meeting_occurred?: boolean | null;
                client_assignee?: number | null;
                billing_status?:
                  | ("no_charge" | "making_standard" | null)
                  | null;
                description_search?: string | null;
                action_pending?: ("admire" | "client" | null) | null;
                code?: string | null;
                allow_org_change?: boolean;
                created_at?: string;
                updated_at?: string | null;
                deleted_at?: string | null;
              };
              originalRecord?: any;
            }[]
          | null;
        allow_org_change?: boolean;
        time_spent?: number | null;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
  };
export type GetSessionsByOrgIdApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
  /** custom filters that can be represented by a stringified object */
  custom?: string;
  /** organization id */
  orgId: number;
};
export type GetUpcomingSessionsApiResponse =
  /** status 200 Session response for clients */ {
    count: number;
    rows: {
      id?: number;
      org_id?: number;
      /** organizations */
      org?: {
        id: number;
        pipedrive_org_id?: number | null;
        name: string;
        phone?: string;
        country_code?: string | null;
        website?: string;
        address?: string;
        consultant_id?: number | null;
        sub_consultant_id?: number | null;
        account_manager_id?: number | null;
        license_status_id?: number | null;
        it_info?: string | null;
        internal_notes?: string | null;
        code?: string;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
        quickbooks_customer_id?: string | null;
      };
      status_id: number;
      title?: string | null;
      pending_session_dates?:
        | {
            value?: string | null;
            inclusive?: boolean | null;
          }[]
        | null;
      created_by_user: {
        id?: number;
        email: string;
        phone?: string | null;
        first_name: string;
        last_name: string;
        [key: string]: any;
      } & {
        roles?:
          | {
              internal_name?: string;
            }[]
          | null;
      };
      client_assignee_user?: {
        id?: number;
        email?: string;
        phone?: string | null;
        first_name?: string;
        last_name?: string;
        roles?:
          | {
              internal_name?: string;
            }[]
          | null;
      } | null;
      assigned_to_user: {
        id?: number;
        email: string;
        phone?: string | null;
        first_name: string;
        last_name: string;
        [key: string]: any;
      } & {
        roles?:
          | {
              internal_name?: string;
            }[]
          | null;
      };
      session_invitees?: {
        id: number;
        session_id: number;
        user_id?: number | null;
        email: string;
        user?:
          | ({
              id?: number;
              email: string;
              phone?: string | null;
              first_name: string;
              last_name: string;
              [key: string]: any;
            } & {
              roles?:
                | {
                    internal_name?: string;
                  }[]
                | null;
            })[]
          | null;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      /** booking */
      booking?: {
        id: number;
        session_id: number;
        name?: string | null;
        start_time: string;
        end_time?: string | null;
        event_uri: string | null;
        join_url: string;
        duration?: number | null;
        meeting_id?: string | null;
        reschedule_url?: string;
        rescheduled?: boolean;
        reason?: string | null;
        play_url?: string | null;
        download_url?: string | null;
        meeting_password?: string | null;
        booking_engine: "calendly" | "other";
        /** booking_types */
        booking_type?: {
          id: number;
          active: boolean;
          admin_managed: boolean;
          booking_method?: string | null;
          color?: string | null;
          description_html?: string | null;
          description_plain?: string | null;
          duration: number;
          internal_note?: string | null;
          kind: string;
          name: string;
          pooling_type?: string | null;
          scheduling_url: string;
          secret: boolean;
          slug?: string | null;
          type: string;
          created_at?: string | null;
          updated_at?: string | null;
          deleted_at?: string | null;
        };
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      } | null;
      tasks?: {
        id?: number;
        org_id?: number;
        code?: string;
        /** statuses schema */
        status?: {
          id?: number;
          internal_name?: string;
          name?: string;
          description?: string;
          type?:
            | (
                | "all"
                | "session"
                | "all_tasks"
                | "task"
                | "internal_task"
                | "custom_work"
                | "quick_question"
                | null
              )
            | null;
          is_automated?: boolean | null;
          transition_phase?:
            | "todo"
            | "in_progress"
            | "action_required"
            | "done"
            | "blocked";
          color?:
            | "green"
            | "red"
            | "yellow"
            | "grey"
            | "primary"
            | "tertiary"
            | "secondary"
            | "lightGrey";
          created_at?: string;
          updated_at?: string | null;
          deleted_at?: string | null;
        };
        sessions?:
          | {
              id?: number;
              org_id: number;
              created_by: number;
              client_assignee?: number | null;
              title?: string | null;
              assigned_to?: number;
              status_id: number;
              pending_session_dates?:
                | {
                    value?: string | null;
                    inclusive?: boolean | null;
                  }[]
                | null;
              session_tasks?: {
                is_active?: boolean | null;
              } | null;
              created_at?: string | null;
              updated_at?: string | null;
              deleted_at?: string | null;
            }[]
          | null;
        media?: ({
          id?: number;
          path?: string;
          filename?: string | null;
          internal_name?: string;
          label?: string;
          mime_type?: string | null;
          size?: number | null;
          status_id?: number | null;
          status?: string | null;
          uploaded_by?: number;
          created_at?: string | null;
          [key: string]: any;
        } & {
          uploaded_by_user?: {
            id?: number;
            email: string;
            phone?: string | null;
            first_name: string;
            last_name: string;
          };
        } & ({
            task_media?: {
              is_custom_work_manual?: boolean | null;
            };
          } | null))[];
        /** topics */
        topic?: {
          id?: number;
          internal_name: string;
          name: string;
          description: string;
          created_at?: string | null;
          updated_at?: string | null;
          deleted_at?: string | null;
        };
        task_chat_count?: number;
        watchers?:
          | {
              id?: number;
              email: string;
              phone?: string | null;
              first_name: string;
              last_name: string;
            }[]
          | null;
        title: string;
        description?: string | null;
        type?: "task" | "quick_question" | "internal_task" | "custom_work";
        priority: "normal" | "high" | "critical";
        internal_priority?: "low" | "medium" | "high";
        assigned_to_user?: {
          id?: number;
          email: string;
          phone?: string | null;
          first_name: string;
          last_name: string;
          [key: string]: any;
        } & {
          roles?:
            | {
                internal_name?: string;
              }[]
            | null;
        };
        reported_by_user?: {
          id?: number;
          email: string;
          phone?: string | null;
          first_name: string;
          last_name: string;
          [key: string]: any;
        } & {
          roles?:
            | {
                internal_name?: string;
              }[]
            | null;
        };
        client_assignee_user?: {
          id?: number;
          email?: string;
          phone?: string | null;
          first_name?: string;
          last_name?: string;
          roles?:
            | {
                internal_name?: string;
              }[]
            | null;
        } | null;
        requested_by_date?: string | null;
        description_search?: string | null;
        session_tasks?: {
          is_active?: boolean | null;
        } | null;
        billing_status?: ("no_charge" | "making_standard" | null) | null;
        action_pending?: ("admire" | "client" | null) | null;
        instant_meeting_url?: string | null;
        instant_meeting_occurred?: boolean | null;
        linkedTasks?:
          | {
              thisTaskId: number;
              linkedTaskId: number;
              linkTypeId: number;
              linkType?: string | null;
              /** tasks schema */
              linkedTask: {
                id?: number;
                status_id?: number;
                topic_id?: number;
                org_id?: number;
                title?: string;
                description?: string | null;
                priority?: "normal" | "high" | "critical";
                internal_priority?: "low" | "medium" | "high";
                assigned_to?: number;
                reported_by?: number;
                type?:
                  | "task"
                  | "quick_question"
                  | "internal_task"
                  | "custom_work";
                requested_by_date?: string | null;
                instant_meeting_url?: string | null;
                instant_meeting_occurred?: boolean | null;
                client_assignee?: number | null;
                billing_status?:
                  | ("no_charge" | "making_standard" | null)
                  | null;
                description_search?: string | null;
                action_pending?: ("admire" | "client" | null) | null;
                code?: string | null;
                allow_org_change?: boolean;
                created_at?: string;
                updated_at?: string | null;
                deleted_at?: string | null;
              };
              originalRecord?: any;
            }[]
          | null;
        allow_org_change?: boolean;
        time_spent?: number | null;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
  };
export type GetUpcomingSessionsApiArg = {
  /** Should Include pending sessions with upcoming */
  includePending?: boolean;
  /** organization id */
  orgId: number;
};
export type GetTodaysSessionsApiResponse =
  /** status 200 Session response for clients */ {
    count: number;
    rows: {
      id?: number;
      org_id?: number;
      /** organizations */
      org?: {
        id: number;
        pipedrive_org_id?: number | null;
        name: string;
        phone?: string;
        country_code?: string | null;
        website?: string;
        address?: string;
        consultant_id?: number | null;
        sub_consultant_id?: number | null;
        account_manager_id?: number | null;
        license_status_id?: number | null;
        it_info?: string | null;
        internal_notes?: string | null;
        code?: string;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
        quickbooks_customer_id?: string | null;
      };
      status_id: number;
      title?: string | null;
      pending_session_dates?:
        | {
            value?: string | null;
            inclusive?: boolean | null;
          }[]
        | null;
      created_by_user: {
        id?: number;
        email: string;
        phone?: string | null;
        first_name: string;
        last_name: string;
        [key: string]: any;
      } & {
        roles?:
          | {
              internal_name?: string;
            }[]
          | null;
      };
      client_assignee_user?: {
        id?: number;
        email?: string;
        phone?: string | null;
        first_name?: string;
        last_name?: string;
        roles?:
          | {
              internal_name?: string;
            }[]
          | null;
      } | null;
      assigned_to_user: {
        id?: number;
        email: string;
        phone?: string | null;
        first_name: string;
        last_name: string;
        [key: string]: any;
      } & {
        roles?:
          | {
              internal_name?: string;
            }[]
          | null;
      };
      session_invitees?: {
        id: number;
        session_id: number;
        user_id?: number | null;
        email: string;
        user?:
          | ({
              id?: number;
              email: string;
              phone?: string | null;
              first_name: string;
              last_name: string;
              [key: string]: any;
            } & {
              roles?:
                | {
                    internal_name?: string;
                  }[]
                | null;
            })[]
          | null;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      /** booking */
      booking?: {
        id: number;
        session_id: number;
        name?: string | null;
        start_time: string;
        end_time?: string | null;
        event_uri: string | null;
        join_url: string;
        duration?: number | null;
        meeting_id?: string | null;
        reschedule_url?: string;
        rescheduled?: boolean;
        reason?: string | null;
        play_url?: string | null;
        download_url?: string | null;
        meeting_password?: string | null;
        booking_engine: "calendly" | "other";
        /** booking_types */
        booking_type?: {
          id: number;
          active: boolean;
          admin_managed: boolean;
          booking_method?: string | null;
          color?: string | null;
          description_html?: string | null;
          description_plain?: string | null;
          duration: number;
          internal_note?: string | null;
          kind: string;
          name: string;
          pooling_type?: string | null;
          scheduling_url: string;
          secret: boolean;
          slug?: string | null;
          type: string;
          created_at?: string | null;
          updated_at?: string | null;
          deleted_at?: string | null;
        };
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      } | null;
      tasks?: {
        id?: number;
        org_id?: number;
        code?: string;
        /** statuses schema */
        status?: {
          id?: number;
          internal_name?: string;
          name?: string;
          description?: string;
          type?:
            | (
                | "all"
                | "session"
                | "all_tasks"
                | "task"
                | "internal_task"
                | "custom_work"
                | "quick_question"
                | null
              )
            | null;
          is_automated?: boolean | null;
          transition_phase?:
            | "todo"
            | "in_progress"
            | "action_required"
            | "done"
            | "blocked";
          color?:
            | "green"
            | "red"
            | "yellow"
            | "grey"
            | "primary"
            | "tertiary"
            | "secondary"
            | "lightGrey";
          created_at?: string;
          updated_at?: string | null;
          deleted_at?: string | null;
        };
        sessions?:
          | {
              id?: number;
              org_id: number;
              created_by: number;
              client_assignee?: number | null;
              title?: string | null;
              assigned_to?: number;
              status_id: number;
              pending_session_dates?:
                | {
                    value?: string | null;
                    inclusive?: boolean | null;
                  }[]
                | null;
              session_tasks?: {
                is_active?: boolean | null;
              } | null;
              created_at?: string | null;
              updated_at?: string | null;
              deleted_at?: string | null;
            }[]
          | null;
        media?: ({
          id?: number;
          path?: string;
          filename?: string | null;
          internal_name?: string;
          label?: string;
          mime_type?: string | null;
          size?: number | null;
          status_id?: number | null;
          status?: string | null;
          uploaded_by?: number;
          created_at?: string | null;
          [key: string]: any;
        } & {
          uploaded_by_user?: {
            id?: number;
            email: string;
            phone?: string | null;
            first_name: string;
            last_name: string;
          };
        } & ({
            task_media?: {
              is_custom_work_manual?: boolean | null;
            };
          } | null))[];
        /** topics */
        topic?: {
          id?: number;
          internal_name: string;
          name: string;
          description: string;
          created_at?: string | null;
          updated_at?: string | null;
          deleted_at?: string | null;
        };
        task_chat_count?: number;
        watchers?:
          | {
              id?: number;
              email: string;
              phone?: string | null;
              first_name: string;
              last_name: string;
            }[]
          | null;
        title: string;
        description?: string | null;
        type?: "task" | "quick_question" | "internal_task" | "custom_work";
        priority: "normal" | "high" | "critical";
        internal_priority?: "low" | "medium" | "high";
        assigned_to_user?: {
          id?: number;
          email: string;
          phone?: string | null;
          first_name: string;
          last_name: string;
          [key: string]: any;
        } & {
          roles?:
            | {
                internal_name?: string;
              }[]
            | null;
        };
        reported_by_user?: {
          id?: number;
          email: string;
          phone?: string | null;
          first_name: string;
          last_name: string;
          [key: string]: any;
        } & {
          roles?:
            | {
                internal_name?: string;
              }[]
            | null;
        };
        client_assignee_user?: {
          id?: number;
          email?: string;
          phone?: string | null;
          first_name?: string;
          last_name?: string;
          roles?:
            | {
                internal_name?: string;
              }[]
            | null;
        } | null;
        requested_by_date?: string | null;
        description_search?: string | null;
        session_tasks?: {
          is_active?: boolean | null;
        } | null;
        billing_status?: ("no_charge" | "making_standard" | null) | null;
        action_pending?: ("admire" | "client" | null) | null;
        instant_meeting_url?: string | null;
        instant_meeting_occurred?: boolean | null;
        linkedTasks?:
          | {
              thisTaskId: number;
              linkedTaskId: number;
              linkTypeId: number;
              linkType?: string | null;
              /** tasks schema */
              linkedTask: {
                id?: number;
                status_id?: number;
                topic_id?: number;
                org_id?: number;
                title?: string;
                description?: string | null;
                priority?: "normal" | "high" | "critical";
                internal_priority?: "low" | "medium" | "high";
                assigned_to?: number;
                reported_by?: number;
                type?:
                  | "task"
                  | "quick_question"
                  | "internal_task"
                  | "custom_work";
                requested_by_date?: string | null;
                instant_meeting_url?: string | null;
                instant_meeting_occurred?: boolean | null;
                client_assignee?: number | null;
                billing_status?:
                  | ("no_charge" | "making_standard" | null)
                  | null;
                description_search?: string | null;
                action_pending?: ("admire" | "client" | null) | null;
                code?: string | null;
                allow_org_change?: boolean;
                created_at?: string;
                updated_at?: string | null;
                deleted_at?: string | null;
              };
              originalRecord?: any;
            }[]
          | null;
        allow_org_change?: boolean;
        time_spent?: number | null;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
  };
export type GetTodaysSessionsApiArg = void;
export type GetSessionsByIdApiResponse =
  /** status 200 Single Session for client */ {
    id?: number;
    org_id?: number;
    /** organizations */
    org?: {
      id: number;
      pipedrive_org_id?: number | null;
      name: string;
      phone?: string;
      country_code?: string | null;
      website?: string;
      address?: string;
      consultant_id?: number | null;
      sub_consultant_id?: number | null;
      account_manager_id?: number | null;
      license_status_id?: number | null;
      it_info?: string | null;
      internal_notes?: string | null;
      code?: string;
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
      quickbooks_customer_id?: string | null;
    };
    status_id: number;
    title?: string | null;
    pending_session_dates?:
      | {
          value?: string | null;
          inclusive?: boolean | null;
        }[]
      | null;
    created_by_user: {
      id?: number;
      email: string;
      phone?: string | null;
      first_name: string;
      last_name: string;
      [key: string]: any;
    } & {
      roles?:
        | {
            internal_name?: string;
          }[]
        | null;
    };
    client_assignee_user?: {
      id?: number;
      email?: string;
      phone?: string | null;
      first_name?: string;
      last_name?: string;
      roles?:
        | {
            internal_name?: string;
          }[]
        | null;
    } | null;
    assigned_to_user: {
      id?: number;
      email: string;
      phone?: string | null;
      first_name: string;
      last_name: string;
      [key: string]: any;
    } & {
      roles?:
        | {
            internal_name?: string;
          }[]
        | null;
    };
    session_invitees?: {
      id: number;
      session_id: number;
      user_id?: number | null;
      email: string;
      user?:
        | ({
            id?: number;
            email: string;
            phone?: string | null;
            first_name: string;
            last_name: string;
            [key: string]: any;
          } & {
            roles?:
              | {
                  internal_name?: string;
                }[]
              | null;
          })[]
        | null;
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
    /** booking */
    booking?: {
      id: number;
      session_id: number;
      name?: string | null;
      start_time: string;
      end_time?: string | null;
      event_uri: string | null;
      join_url: string;
      duration?: number | null;
      meeting_id?: string | null;
      reschedule_url?: string;
      rescheduled?: boolean;
      reason?: string | null;
      play_url?: string | null;
      download_url?: string | null;
      meeting_password?: string | null;
      booking_engine: "calendly" | "other";
      /** booking_types */
      booking_type?: {
        id: number;
        active: boolean;
        admin_managed: boolean;
        booking_method?: string | null;
        color?: string | null;
        description_html?: string | null;
        description_plain?: string | null;
        duration: number;
        internal_note?: string | null;
        kind: string;
        name: string;
        pooling_type?: string | null;
        scheduling_url: string;
        secret: boolean;
        slug?: string | null;
        type: string;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      };
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
    } | null;
    tasks?: {
      id?: number;
      org_id?: number;
      code?: string;
      /** statuses schema */
      status?: {
        id?: number;
        internal_name?: string;
        name?: string;
        description?: string;
        type?:
          | (
              | "all"
              | "session"
              | "all_tasks"
              | "task"
              | "internal_task"
              | "custom_work"
              | "quick_question"
              | null
            )
          | null;
        is_automated?: boolean | null;
        transition_phase?:
          | "todo"
          | "in_progress"
          | "action_required"
          | "done"
          | "blocked";
        color?:
          | "green"
          | "red"
          | "yellow"
          | "grey"
          | "primary"
          | "tertiary"
          | "secondary"
          | "lightGrey";
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      };
      sessions?:
        | {
            id?: number;
            org_id: number;
            created_by: number;
            client_assignee?: number | null;
            title?: string | null;
            assigned_to?: number;
            status_id: number;
            pending_session_dates?:
              | {
                  value?: string | null;
                  inclusive?: boolean | null;
                }[]
              | null;
            session_tasks?: {
              is_active?: boolean | null;
            } | null;
            created_at?: string | null;
            updated_at?: string | null;
            deleted_at?: string | null;
          }[]
        | null;
      media?: ({
        id?: number;
        path?: string;
        filename?: string | null;
        internal_name?: string;
        label?: string;
        mime_type?: string | null;
        size?: number | null;
        status_id?: number | null;
        status?: string | null;
        uploaded_by?: number;
        created_at?: string | null;
        [key: string]: any;
      } & {
        uploaded_by_user?: {
          id?: number;
          email: string;
          phone?: string | null;
          first_name: string;
          last_name: string;
        };
      } & ({
          task_media?: {
            is_custom_work_manual?: boolean | null;
          };
        } | null))[];
      /** topics */
      topic?: {
        id?: number;
        internal_name: string;
        name: string;
        description: string;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      };
      task_chat_count?: number;
      watchers?:
        | {
            id?: number;
            email: string;
            phone?: string | null;
            first_name: string;
            last_name: string;
          }[]
        | null;
      title: string;
      description?: string | null;
      type?: "task" | "quick_question" | "internal_task" | "custom_work";
      priority: "normal" | "high" | "critical";
      internal_priority?: "low" | "medium" | "high";
      assigned_to_user?: {
        id?: number;
        email: string;
        phone?: string | null;
        first_name: string;
        last_name: string;
        [key: string]: any;
      } & {
        roles?:
          | {
              internal_name?: string;
            }[]
          | null;
      };
      reported_by_user?: {
        id?: number;
        email: string;
        phone?: string | null;
        first_name: string;
        last_name: string;
        [key: string]: any;
      } & {
        roles?:
          | {
              internal_name?: string;
            }[]
          | null;
      };
      client_assignee_user?: {
        id?: number;
        email?: string;
        phone?: string | null;
        first_name?: string;
        last_name?: string;
        roles?:
          | {
              internal_name?: string;
            }[]
          | null;
      } | null;
      requested_by_date?: string | null;
      description_search?: string | null;
      session_tasks?: {
        is_active?: boolean | null;
      } | null;
      billing_status?: ("no_charge" | "making_standard" | null) | null;
      action_pending?: ("admire" | "client" | null) | null;
      instant_meeting_url?: string | null;
      instant_meeting_occurred?: boolean | null;
      linkedTasks?:
        | {
            thisTaskId: number;
            linkedTaskId: number;
            linkTypeId: number;
            linkType?: string | null;
            /** tasks schema */
            linkedTask: {
              id?: number;
              status_id?: number;
              topic_id?: number;
              org_id?: number;
              title?: string;
              description?: string | null;
              priority?: "normal" | "high" | "critical";
              internal_priority?: "low" | "medium" | "high";
              assigned_to?: number;
              reported_by?: number;
              type?:
                | "task"
                | "quick_question"
                | "internal_task"
                | "custom_work";
              requested_by_date?: string | null;
              instant_meeting_url?: string | null;
              instant_meeting_occurred?: boolean | null;
              client_assignee?: number | null;
              billing_status?: ("no_charge" | "making_standard" | null) | null;
              description_search?: string | null;
              action_pending?: ("admire" | "client" | null) | null;
              code?: string | null;
              allow_org_change?: boolean;
              created_at?: string;
              updated_at?: string | null;
              deleted_at?: string | null;
            };
            originalRecord?: any;
          }[]
        | null;
      allow_org_change?: boolean;
      time_spent?: number | null;
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
export type GetSessionsByIdApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type UpdateSessionTitleApiResponse =
  /** status 204 An empty response */ void;
export type UpdateSessionTitleApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** sessions */
  body: {
    title: string;
  };
};
export type CreateSessionApiResponse = /** status 200 id */ {
  id: number;
};
export type CreateSessionApiArg = {
  /** organization id */
  orgId: number;
  /** sessions */
  body: {
    id?: number;
    title?: string | null;
    session_id?: number | null;
    created_by?: number;
    client_assignee?: number;
    assigned_to: number;
    pending_session_dates?: (string | null)[] | null;
    event?: {
      uri?: string;
    };
    invitee?: {
      uri?: string;
    };
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
};
export type GetOrgSessionActivitiesApiResponse =
  /** status 200 activities response */ {
    activities: ({
      id: number;
      type: string;
      title: string;
      entity: string;
      entity_id: number;
      created_by: number;
      value?: string | null;
      old_value?: string | null;
      created_at: string;
    } & {
      created_by_user: {
        id: number;
        first_name: string;
        last_name: string;
      };
    })[];
  };
export type GetOrgSessionActivitiesApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type GetSessionTasksApiResponse =
  /** status 200 catch all response response */ {
    data: {
      id: number;
      status_id: number;
      topic_id: number;
      org_id?: number;
      title: string;
      description?: string | null;
      priority?: "normal" | "high" | "critical";
      internal_priority?: "low" | "medium" | "high";
      assigned_to: number;
      reported_by: number;
      type: "task" | "quick_question" | "internal_task" | "custom_work";
      requested_by_date?: string | null;
      instant_meeting_url?: string | null;
      instant_meeting_occurred?: boolean | null;
      client_assignee: number | null;
      billing_status?: ("no_charge" | "making_standard" | null) | null;
      description_search?: string | null;
      action_pending?: ("admire" | "client" | null) | null;
      code?: string | null;
      allow_org_change?: boolean;
      created_at: string;
      updated_at?: string | null;
      deleted_at?: string | null;
      task_watchers: {
        id?: number;
        task_id?: number;
        user_id?: number;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      session_start_time?: string;
      pending_session_dates?:
        | {
            value?: string | null;
            inclusive?: boolean | null;
          }[]
        | null;
      session_title?: string | null;
      session_end_time?: string | null;
      session_duration?: number | null;
      has_active_session?: boolean;
      session_status_id?: number | null;
      task_chat_count: number;
      media_count: number;
      new_chats_count: number;
    }[];
  };
export type GetSessionTasksApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type GetSessionActivitiesApiResponse =
  /** status 200 activities response */ {
    activities: ({
      id: number;
      type: string;
      title: string;
      entity: string;
      entity_id: number;
      created_by: number;
      value?: string | null;
      old_value?: string | null;
      created_at: string;
    } & {
      created_by_user: {
        id: number;
        first_name: string;
        last_name: string;
      };
    })[];
  };
export type GetSessionActivitiesApiArg = {
  /** record id */
  id: number;
};
export const {
  useGetSessionsQuery,
  useLazyGetSessionsQuery,
  useCreatePendingSessionMutation,
  useGetSessionQuery,
  useLazyGetSessionQuery,
  useRescheduleFromExistingSessionMutation,
  useUpdateSessionMutation,
  useDeleteSessionMutation,
  useUpdateSessionStatusMutation,
  useCancelSessionMutation,
  useRestoreSessionMutation,
  useMarkSessionNoShowMutation,
  useGetTimeEntriesQuery,
  useLazyGetTimeEntriesQuery,
  useCreateTimeEntryMutation,
  useGetTimeEntryQuery,
  useLazyGetTimeEntryQuery,
  useUpdateTimeEntryMutation,
  useDeleteTimeEntryMutation,
  useGetSessionsByOrgIdQuery,
  useLazyGetSessionsByOrgIdQuery,
  useGetUpcomingSessionsQuery,
  useLazyGetUpcomingSessionsQuery,
  useGetTodaysSessionsQuery,
  useLazyGetTodaysSessionsQuery,
  useGetSessionsByIdQuery,
  useLazyGetSessionsByIdQuery,
  useUpdateSessionTitleMutation,
  useCreateSessionMutation,
  useGetOrgSessionActivitiesQuery,
  useLazyGetOrgSessionActivitiesQuery,
  useGetSessionTasksQuery,
  useLazyGetSessionTasksQuery,
  useGetSessionActivitiesQuery,
  useLazyGetSessionActivitiesQuery,
} = injectedRtkApi;
