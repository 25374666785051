import { Button, FormControlLabel, Stack, Typography } from "@mui/material";
import {
  GetTasksApiArg,
  GetTasksApiResponse,
} from "../../state/rtk-query/state/tasks";
import { useTaskDrawerIsOpen } from "../hooks";
import { useMobile } from "../../themes";
import { SortableButton } from "../buttons";
import { useGetTopicsQuery } from "../../state/rtk-query/state/topics";
import {
  useAdmireUsersList,
  useOrgsList,
  useStatusesList,
} from "../hooks/useLists";
import { useSelector, authSelectors } from "../../state";
import { CustomCheckBox } from "../styled";
import { ChangeEvent, useMemo } from "react";
import { useTaskParams } from "../hooks/useTaskParams";

type TaskRes = GetTasksApiResponse["rows"][number];

const TASK_TYPES = [
  { id: "quick_question", label: "Quick Question" },
  { id: "custom_work", label: "Custom Work" },
  { id: "on_session", label: "On Consulting Session" },
  { id: "unscheduled", label: "Unscheduled" },
] as const;

export interface TaskQueryFilters {
  taskAssignees?: number[];
  taskType?: NonNullable<GetTasksApiArg["type"]>;
  taskTopics?: number[];
  taskStatuses?: string[];
  taskOrgs?: number[];
  taskFilterMyOrgs: boolean;
  taskPriority?: NonNullable<TaskRes["priority"]>[];
  taskInternalPriority?: NonNullable<TaskRes["internal_priority"]>[];
  newComments?: boolean;
  taskOpenTasks?: boolean;
  taskAwaiting?: "admire" | "client";
  page?: number;
  dateRange?: string[];
  taskArchive?: boolean;
}

interface TaskFilterProps {
  isTable?: boolean;
  orgId: number;
  inOrgView?: boolean;
}

export function TaskFilters({ isTable, orgId, inOrgView }: TaskFilterProps) {
  const isMobile = useMobile();
  const drawerIsOpen = useTaskDrawerIsOpen();
  const isInternal = useSelector(authSelectors.isInternal);
  const isConsultant = useSelector(authSelectors.isConsultant);
  const isAccountManager = useSelector(authSelectors.isAccountManager);
  const isCustomWorkUser = useSelector(authSelectors.isCustomWorkUser);
  const userId = useSelector(authSelectors.userId);
  const shouldDefaultToSelf = isConsultant || isCustomWorkUser;

  const [params, setParams, clear, isClear] = useTaskParams(isTable);

  const {
    taskFilterMyOrgs,
    taskAssignees,
    taskAwaiting,
    taskInternalPriority,
    taskOpenTasks,
    taskOrgs,
    taskPriority,
    taskStatuses,
    taskTopics,
    taskType,
    dateRange,
  } = params;

  const { data: topics } = useGetTopicsQuery(undefined, {
    refetchOnMountOrArgChange: !drawerIsOpen,
  });

  const statusType = !taskType
    ? "all_tasks"
    : taskType.includes("on_session") || taskType.includes("unscheduled")
    ? "task"
    : "all_tasks";
  const { statuses: allStatuses } = useStatusesList(statusType);
  const { admireUsers: users, getAdmireUser: getUser } = useAdmireUsersList(
    !isInternal && orgId < 1,
  );
  const sortedUsers = useMemo(
    () =>
      shouldDefaultToSelf
        ? [getUser(userId)!, ...users.filter((u) => u.id !== userId)]
        : users,
    [shouldDefaultToSelf, userId, users],
  );
  const { orgs } = useOrgsList(inOrgView || !isInternal);

  function noEmptyArray(v?: any[]) {
    if (v?.length) {
      return v;
    }
    return undefined;
  }

  const sortButton = (
    f: number | string,
    c: boolean,
    sortable: keyof Omit<
      TaskQueryFilters,
      "taskFilterMyOrgs" | "newComments" | "taskOpenTasks" | "taskAwaiting"
    >,
    ...clearFilters: (keyof TaskQueryFilters)[]
  ) => {
    const values = params[sortable] as any[] | undefined;
    setParams(
      {
        [sortable]: c
          ? [...(values || []), f]
          : noEmptyArray(values?.filter((p) => p !== f)),
      },
      ...clearFilters,
    );
  };

  return (
    <Stack
      direction={isMobile && !isTable ? "column" : "row"}
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      py={isTable ? 1 : undefined}
      height={!isTable ? "auto" : undefined}
    >
      <Stack
        direction={"row"}
        width={"100%"}
        overflow={"auto"}
        sx={{
          "::-webkit-scrollbar": { display: "none" },
          scrollbarWidth: "none",
        }}
      >
        <SortableButton
          title="Status"
          onClick={(f, c) => sortButton(f, c, "taskStatuses", "page")}
          isDropDown
          displayItems={[
            ...new Map(allStatuses?.map((s) => [s.internal_name, s.name])),
          ].map(([internal_name, name]) => ({
            id: internal_name,
            label: name,
          }))}
          currentSort={taskStatuses}
        />
        {!isTable && (
          <SortableButton
            title="Date Created"
            onChange={(range) => {
              setParams(
                {
                  dateRange: range[0] && range[1] ? range : undefined,
                },
                "page",
              );
            }}
            isDropDown
            isDate
            currentSort={dateRange as any as Date[]}
          />
        )}
        {isInternal && !!sortedUsers?.length && (
          <SortableButton
            title="Assignee"
            onClick={(f, c) =>
              setParams(
                {
                  taskAssignees: c
                    ? [...(taskAssignees || []), f]
                    : taskAssignees?.filter((a) => a !== f),
                },
                "page",
              )
            }
            isDropDown
            displayItems={sortedUsers.map((u) => {
              return {
                label: `${u?.first_name || ""} ${u?.last_name || ""}`,
                id: u?.id,
              };
            })}
            currentSort={taskAssignees}
          />
        )}
        {isTable && (
          <SortableButton
            title="Internal Priority"
            onClick={(f, c) => sortButton(f, c, "taskInternalPriority", "page")}
            isDropDown
            displayItems={["Low", "Medium", "High"].map((l) => {
              return {
                label: l,
                id: l.toLowerCase(),
              };
            })}
            currentSort={taskInternalPriority?.filter(Boolean) ?? []}
          />
        )}
        {isTable && (
          <SortableButton
            title="Priority"
            onClick={(f, c) => sortButton(f, c, "taskPriority", "page")}
            isDropDown
            displayItems={["Normal", "High", "Critical"].map((l) => {
              return {
                label: l,
                id: l.toLowerCase(),
              };
            })}
            currentSort={(taskPriority ?? [])?.filter(Boolean)}
          />
        )}
        <SortableButton
          title="Type"
          onClick={(f, c) =>
            setParams(
              {
                taskType: c
                  ? [...(taskType || []), f]
                  : taskType?.filter((a) => a !== f),
              },
              "page",
            )
          }
          isDropDown
          displayItems={TASK_TYPES}
          currentSort={taskType}
        />
        <SortableButton
          title="Awaiting"
          onClick={(f, c) =>
            setParams(
              {
                taskAwaiting: c
                  ? (f.toLowerCase() as "admire" | "client")
                  : undefined,
              },
              "page",
            )
          }
          isDropDown
          items={["Admire", "Client"]}
          isSingleSelect
          singleSelectSort={
            taskAwaiting
              ? taskAwaiting[0].toUpperCase() + taskAwaiting.substring(1)
              : ""
          }
        />
        <SortableButton
          title="Topic"
          onClick={(f, c) => sortButton(f, c, "taskTopics", "page")}
          isDropDown
          displayItems={(topics?.rows || []).map((s) => {
            return {
              label: s.name,
              id: s.id!,
            };
          })}
          currentSort={taskTopics}
        />
        {!inOrgView && isTable && (
          <SortableButton
            title="Orgs"
            onClick={(f, c) => {
              sortButton(f, c, "taskOrgs");
            }}
            isDropDown
            displayItems={orgs.map((o) => {
              return {
                label: o.name,
                id: o.id,
              };
            })}
            currentSort={taskOrgs}
          />
        )}
        {!inOrgView && isTable && (isConsultant || isAccountManager) && (
          <FormControlLabel
            sx={{
              height: "min-content",
              display: "flex",
              alignItems: "center",
              "& > span:last-child": {
                fontSize: isMobile ? 14 : 16,
                display: "flex",
                alignItems: "center",
                fontWeight: 500,
              },
              whiteSpace: "nowrap",
            }}
            labelPlacement="end"
            control={
              <CustomCheckBox
                props={{
                  checked: taskFilterMyOrgs && !taskOrgs?.length,
                }}
                onChange={(
                  _e: ChangeEvent<HTMLInputElement>,
                  checked: boolean,
                ) =>
                  setParams(
                    {
                      taskFilterMyOrgs: checked,
                    },
                    "taskOrgs",
                  )
                }
              />
            }
            label="My orgs"
          />
        )}
        <FormControlLabel
          sx={{
            height: "min-content",
            display: "flex",
            alignItems: "center",
            "& > span:last-child": {
              fontSize: isMobile ? 14 : 16,
              display: "flex",
              alignItems: "center",
              fontWeight: 500,
            },
            whiteSpace: "nowrap",
          }}
          labelPlacement="end"
          control={
            <CustomCheckBox
              props={{
                checked: taskOpenTasks && !taskStatuses?.length,
              }}
              onChange={(_e: ChangeEvent<HTMLInputElement>, checked: boolean) =>
                setParams(
                  {
                    taskOpenTasks: checked,
                  },
                  "taskStatuses",
                  "page",
                )
              }
            />
          }
          label="Open tasks"
        />
      </Stack>
      {!isClear && (
        <Button
          variant="text"
          color="info"
          sx={{ minWidth: "fit-content" }}
          size="small"
          onClick={clear}
        >
          <Typography fontSize={16} fontWeight={"500"}>
            Reset filters
          </Typography>
        </Button>
      )}
    </Stack>
  );
}
