import { Stack, Typography, Link, SxProps } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Fragment } from "react";
import { RouterLink } from "../../lib";
import { HomepageSectionStyled } from "../../pages/main/shared";
import {
  useGetUpcomingSessionsQuery,
  useGetTodaysSessionsQuery,
} from "../../state/rtk-query/state/session";
import { MiniSessionCard } from "../cards";
import { useGetStatusId, useOrgId } from "../hooks";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { useMobile, useTablet } from "../../themes";

export const UpcomingSessionsSideBar = ({
  orgId: orgIdProp,
  sx,
}: {
  orgId?: number;
  sx?: SxProps;
}) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isSmallerThanPc = isMobile || isTablet;
  const orgIdHook = useOrgId();
  const orgId = orgIdProp !== undefined ? orgIdProp : orgIdHook;

  const isInternal = useSelector(authSelectors.isInternal);
  const upcomingStatus = useGetStatusId("upcoming");

  const displayTodays = isInternal && orgIdProp === undefined;
  const { currentData: upcomingSessions, isSuccess: upComingSuccess } =
    useGetUpcomingSessionsQuery(
      orgId
        ? {
            orgId,
          }
        : skipToken,
      { skip: displayTodays, refetchOnMountOrArgChange: true },
    );
  const {
    currentData: todaysSessions,

    isSuccess: todaySuccess,
  } = useGetTodaysSessionsQuery(undefined, {
    skip: !displayTodays,
    refetchOnMountOrArgChange: true,
  });
  const isSuccess = todaySuccess || upComingSuccess;

  const sessionsToDisplay = displayTodays ? todaysSessions : upcomingSessions;
  const hasSessions = sessionsToDisplay?.rows?.length && isSuccess;

  return (
    <HomepageSectionStyled sx={{ pr: "auto", ...sx }}>
      <Stack
        direction={isSmallerThanPc ? "column" : "row"}
        justifyContent={"space-between"}
        alignItems={isSmallerThanPc ? "flex-start" : "center"}
        width={"100%"}
        gap={!isSmallerThanPc ? 1 : 0}
      >
        <Typography variant="h2" fontSize={24}>
          {displayTodays ? "Todays' sessions" : "Upcoming sessions"}
        </Typography>
        {hasSessions || isInternal ? (
          <Link
            component={RouterLink}
            to={
              displayTodays
                ? `/sessions?sessionFilterMyOrgs=true&sessionStatuses[]=${upcomingStatus}`
                : isInternal
                ? `/organizations/${orgId}?tab=sessions`
                : "/sessions"
            }
            fontSize={14}
            fontWeight={600}
            pl={isSmallerThanPc ? 0.5 : 0}
          >
            View all{displayTodays ? " upcoming" : ""}
          </Link>
        ) : null}
      </Stack>
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={hasSessions ? "center" : "flex-start"}
        my={3}
        gap={2}
      >
        {hasSessions ? (
          sessionsToDisplay.rows
            .map((s) => {
              return (
                s.id && (
                  <Fragment key={s?.id}>
                    <MiniSessionCard
                      session={s}
                      displayTodays={displayTodays}
                    />
                  </Fragment>
                )
              );
            })
            .slice(0, isInternal ? sessionsToDisplay?.rows?.length : 4)
        ) : isSuccess ? (
          <Typography variant="h4" mt={2} color={"GrayText"}>
            {displayTodays
              ? "You have no sessions for today."
              : "You have no upcoming sessions."}
          </Typography>
        ) : null}
      </Stack>
    </HomepageSectionStyled>
  );
};
