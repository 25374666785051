import { GetOrgTermsApiResponse } from "../../state/rtk-query/state/terms";
import { differenceInMonths } from "date-fns";
import { castZero, fixJsFormattedDate, formatDate, pluralize } from "../../lib";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { BaseModal } from "../forms";

const ListItemStyle = ({
  name,
  value,
}: {
  name: string;
  value: string | number;
}) => {
  return (
    <Box component={"li"} width={"100%"}>
      {" "}
      <Box component={"span"} fontWeight={700}>
        {name}:{" "}
      </Box>
      <Box component={"span"} fontWeight={500}>
        {value}
      </Box>
    </Box>
  );
};
interface Props {
  terms: GetOrgTermsApiResponse | undefined;
  totalHours?: number;
}
export const OrgTermsModal = ({ terms, totalHours }: Props) => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);

  const { start_date, end_date } = terms ?? {};

  const includedSessions =
    terms?.term_items?.find(
      (t) => t.item?.internal_name === "included_sessions",
    )?.quantity ?? 0;

  const addedSessions = terms?.term_items?.find(
    (t) => t.item?.internal_name === "added_sessions",
  );
  const addedCW = terms?.term_items?.find(
    (t) => t.item?.internal_name === "added_custom_work",
  );

  const includedCW =
    terms?.term_items?.find(
      (t) => t.item?.internal_name === "included_custom_work",
    )?.quantity ?? 0;

  if (!start_date || !end_date) return null;
  // terms are off by a month since they do Feb 1 - Jan 31st as opposed to full months
  const termLength =
    differenceInMonths(new Date(end_date), new Date(start_date)) + 1;

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Typography color={palette.grey[900]} fontWeight={500} fontSize={12}>
        This term includes{" "}
        <Box component={"span"} fontWeight={"bold"}>
          {pluralize(
            Number(
              castZero(
                totalHours ??
                  includedSessions + castZero(addedSessions?.quantity),
              ).toFixed(2),
            ),
            "session",
          )}
        </Box>
      </Typography>
      <Button
        onClick={() => setOpen(true)}
        sx={{ p: 0, height: "15px", fontSize: 12, fontWeight: 600 }}
      >
        Details
      </Button>

      <BaseModal open={open} onClose={() => setOpen(false)} title="Terms">
        <Box
          component={"ul"}
          gap={1}
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
        >
          <ListItemStyle
            name={"Start date"}
            value={formatDate(
              new Date(fixJsFormattedDate(start_date)),
              "MMMM do, yyyy",
            )}
          />
          <ListItemStyle name={"Term"} value={pluralize(termLength, "month")} />
          <ListItemStyle name={"Included sessions"} value={includedSessions} />
          {addedSessions ? (
            <ListItemStyle
              name={"Additional sessions"}
              value={`${addedSessions?.quantity} @ $${addedSessions?.rate}`}
            />
          ) : null}
          {includedCW ? (
            <ListItemStyle
              name={"Included professional service hours (custom)"}
              value={includedCW}
            />
          ) : null}
          {addedCW ? (
            <ListItemStyle
              name={"Additional professional service hours (custom)"}
              value={`${addedCW?.quantity} @ $${addedCW?.rate}`}
            />
          ) : null}
        </Box>
        <Typography variant="subtitle1">
          *One session is equivalent to 60 minutes.
        </Typography>
      </BaseModal>
    </Stack>
  );
};
