import { api } from "../api";
export const addTagTypes = ["templates"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getTemplates: build.query<GetTemplatesApiResponse, GetTemplatesApiArg>({
        query: () => ({ url: `/templates` }),
        providesTags: ["templates"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetTemplatesApiResponse =
  /** status 200 list of entity ids and names */ {
    id: number;
    name: string;
  }[];
export type GetTemplatesApiArg = void;
export const { useGetTemplatesQuery, useLazyGetTemplatesQuery } =
  injectedRtkApi;
